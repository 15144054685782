@import "../../styles/tokens";

.support-description {
    color: var(--alter-secondary-text-color);
    line-height: 24px;

    &__item {
        display: flex;
    }

    &__headline {
        color: var(--alter-primary-text-color);
    }

    &__link {
        color: inherit;
        text-decoration: underline;
        margin-left: 4px;

        &:hover,
        &:active {
            color: var(--alter-secondary-hover-color);
        }
    }

    &__bottom {
        border-top: 1px solid $alter-gray;
        padding-top: 8px;
        margin-top: 9px;
    }

    &.is-dark {
        color: $alter-dark;
    }
}
