@import "../../../../node_modules/bulma/sass/utilities/mixins";
@import "../../../ui-kit/components-branded/Headline/styles";
@import "after-call";
@import "in-call-banner";
@import "call-notification";
@import "../../../ui-kit/styles-branded/misc";

$columns-min-height: min(600px, var(--window-height));
$floating-window-offset: 8px;
$banner-height: 56px;

.user-activation {
    color: $alter-white;
}

.lobby-call-page {
    &.container {
        max-width: 1328px;
        width: 100%;
        padding-right: 8px;
        padding-left: 8px;
        margin-top: 24px;

        @include desktop {
            padding-left: 28px;
            padding-right: 28px;
            flex-grow: 0;
        }
    }

    &__columns.columns {
        margin-left: -8px;
        margin-right: -8px;

        @include desktop {
            margin-left: -16px;
            margin-right: -16px;
        }
    }

    &__column + &__column {
        margin-top: -8px;

        @include desktop {
            margin-top: initial;
        }
    }

    &__footer {
        border-top: none;

        @include desktop {
            border-top: solid 1px var(--alter-separator-color);
        }
    }

    &__browser-warning {
        margin-bottom: 24px;
    }
}

.video-spinner {
    position: absolute;
    margin: auto;
    inset: 0;
}

.info-card {
    position: relative;
    height: 100%;

    &.box {
        padding: 24px;
        box-shadow: none;
    }

    @include desktop {
        min-height: $columns-min-height;
    }

    &__header {
        font-weight: $weight-medium;

        @include tablet {
            @include headline3-bold;
        }
    }

    &__welcome {
        color: $alter-white-60;

        @include tablet {
            @include headline3-medium;
        }
    }
}

.instructions-popup {
    @include tablet {
        width: 674px;
    }

    &__link.button.blm-button {
        @include tablet {
            @include headline3-bold;
        }
    }
}

.video-container {
    opacity: 1;

    &__hidden {
        visibility: hidden;
    }

    &.is-lobby {
        &:empty {
            height: unset;
        }
    }

    &:empty,
    & > video {
        display: block;
        min-height: min(600px, var(--window-height));
        max-height: var(--window-height);
        width: 100%;
        background-color: rgb(25 25 25);
        box-shadow: $box-shadow;
    }

    &__mirror {
        transform: scale(1, -1) rotate(180deg);
    }

    &__round-corners {
        &:empty,
        & > video {
            border-radius: 8px;
        }
    }

    &:empty,
    &__hidden {
        height: var(--window-height);

        @include desktop {
            max-width: 100%;
        }

        &.is-contrast-bg-mobile {
            background-color: hsl(229deg 14% 28%);
        }
    }

    &__is-loading {
        opacity: 0;
    }

    &.has-speaker-border {
        & > video,
        &:empty {
            outline: solid 2px transparent;
            transition: $alter-control-transition;
        }

        &.is-inbound {
            min-height: unset;
            max-height: 160px;
            aspect-ratio: 4 / 3;

            & > video,
            &:empty {
                outline-color: var(--alter-call-inbound-color);
            }
        }

        &.is-outbound {
            & > video,
            &:empty {
                outline-color: var(--alter-call-outbound-color);
            }
        }

        &.is-fullscreen-mobile {
            & > video,
            &:empty {
                border: solid 2px transparent;

                @include desktop {
                    border: none;
                }
            }

            &.is-inbound {
                & > video,
                &:empty {
                    border-color: var(--alter-call-inbound-color);
                }
            }

            &.is-outbound {
                & > video,
                &:empty {
                    border-color: var(--alter-call-outbound-color);
                }
            }
        }
    }
}

.video-container-wrapper {
    position: relative;
    flex-grow: 1;
}

.lobby-column.column {
    padding-top: 12px;
    padding-bottom: 12px;

    @include desktop {
        padding: 16px;
    }
}

.go-to-call-button {
    &__wrapper {
        padding: 0 8px 8px;
        box-shadow: 0 8px 48px -8px rgba(0 0 0 / 45%);
        background-color: transparent;
    }

    .bottom-fixed {
        display: block;

        @include desktop {
            display: none;
        }
    }

    &_is-desktop.button.is-fullwidth {
        display: none;

        @include desktop {
            display: flex;
        }
    }

    &__link {
        &:hover {
            text-decoration: none;
        }
    }

    .button.is-primary[disabled] {
        color: $alter-dark-60;
        background: #97989f;
        border-color: inherit;
    }
}

.room-call-page {
    width: 100%;
    gap: 8px;

    &__side-by-side {
        padding: 0 16px;

        .video-container video,
        .video-container:empty {
            max-height: min(600px, var(--window-height));
        }
    }

    &__video-frame {
        position: relative;
        min-height: $columns-min-height;
        flex: 1 1 0;
        display: flex;
    }

    &__floating {
        display: block;
        position: fixed !important;
        min-width: 100px;
        min-height: initial;
        max-width: 33vw;
        top: $floating-window-offset;
        right: 8px;
        z-index: 1;

        .video-container {
            &__video-hidden {
                height: 160px;
            }

            /* stylelint-disable-next-line no-descending-specificity */
            &__video-hidden,
            & > video {
                min-height: unset;
                max-height: 160px;
                aspect-ratio: 4 / 3;
            }
        }
    }

    &__floating-with-banner {
        top: calc($floating-window-offset + $banner-height);
    }

    &__auto-grid {
        gap: 8px;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-auto-rows: auto;
        grid-template-rows: 1fr;

        @include tablet {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    &__screen-video {
        min-height: min(600px, var(--window-height));
    }
}

.user-avatar-placeholder {
    position: absolute;
    margin: auto;
    inset: 0;

    &.user-avatar {
        background-color: $alter-dark;
        color: $alter-white;
        border-color: $alter-dark;
    }
}

.video-controls {
    position: absolute;
    z-index: 1;
    display: flex;
    gap: 32px;
    bottom: 32px;
    left: 0;
    right: 0;
    justify-content: center;

    @include mobile {
        gap: 16px;
    }

    &__button {
        height: 64px;
        background: rgb(0 0 0 / 35%);
        border-radius: 32px;
        display: flex;
        align-items: center;
        width: 92px;
        padding-left: 20px;

        path {
            fill: $alter-white;
        }

        &.is-rounded {
            cursor: pointer;
            width: 64px;
            min-width: 64px;
            padding: 0;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: $alter-control-transition;
        }

        &.is-red {
            background: rgb(219 98 88 / 32%);

            &:hover,
            &.is-hovered {
                background: $alter-danger-lite;
            }
        }

        &.is-enabled {
            background: $alter-primary-lite;
        }

        &.is-locked {
            pointer-events: none;
            cursor: progress;

            & > * {
                transition: $alter-control-transition;
            }

            .is-body-touched & > * {
                transform: initial;
            }

            .spinner-redesign {
                margin: unset !important;
                width: 32px;
                height: 32px;
            }
        }
    }

    &__icon {
        padding: 0;
    }

    &__selector {
        padding-left: 2px;
    }

    &__opened {
        transform: rotate(-0.5turn);
    }

    &__overlay {
        width: 314px;
        border-radius: 8px;
        background: rgb(0 0 0 / 35%);
        z-index: 1;
    }

    &__overlay-icon {
        width: 24px;
        height: 24px;
        vertical-align: -5px;
    }

    @supports (backdrop-filter: blur(40px)) {
        &__button,
        &__overlay {
            background: rgb(0 0 0 / 8%);
            backdrop-filter: blur(40px);
        }
    }

    &__content {
        color: $alter-white;
        padding: 8px;

        path {
            fill: $alter-white;
        }

        a {
            color: $alter-white;
        }
    }

    &__item {
        padding: 12px 16px;

        &:not(:last-child) {
            border-bottom: solid 1px rgb(240 238 235 / 10%);
        }

        &_is-clickable {
            cursor: pointer;
        }
    }

    &__label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }

    &__check-mark {
        display: inline-block;
        margin-left: 8px;
    }

    &.is-fixed {
        position: fixed;

        &.is-detect-idle {
            & > * {
                transition: $alter-control-transition;
            }

            & > *:not(:first-child),
            & > *:not(:last-child) {
                transform: translate(0, 100px);
            }

            & > *:is(.modal) {
                transform: none;
            }

            & > *:first-child {
                transform: translate(-200px, 100px);
            }

            & > *:last-child {
                transform: translate(200px, 100px);
            }

            .is-body-touched & > * {
                transform: initial;
            }
        }
    }
}

.instructions-popup__headline {
    font-size: $size-normal;
    line-height: 1.33;
    font-weight: $weight-medium;

    @include tablet {
        @include headline3-bold;
    }

    &:not(:first-child) {
        margin-top: 16px;

        @include tablet {
            margin-top: 24px;
        }
    }
}

.user-info {
    &__photo-placeholder {
        width: 48px;
        height: 48px;
        background-color: $alter-primary-dark;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__titles {
        color: $alter-white-30;
    }
}

.video-window-information {
    padding: 0 8px;
    background: rgb(50 54 72 / 30%);
    color: $alter-white;
    white-space: nowrap;
    border-radius: 4px;
    max-width: calc(100% - 8px);
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;

    @supports (backdrop-filter: blur(40px)) {
        & {
            background: rgb(50 54 72 / 30%);
            backdrop-filter: blur(40px);
        }
    }

    &.is-my-video {
        top: 4px;
        left: 4px;
    }
}

.video-user-name {
    position: absolute;
    top: 4px;
    left: 4px;

    &.is-video-hidden {
        top: 4px;
        right: 4px;
        left: unset;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
    }
}

.video-auto-grid {
    grid-gap: 8px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-rows: auto;
    grid-template-rows: 1fr;

    .video-container.video-container.video-container:empty,
    .video-container.video-container.video-container video {
        min-height: auto;
        height: auto;
    }
}

.change-video-toggle {
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;
    display: flex;
    height: 24px;
    align-items: center;

    &__sizes {
        display: flex;
        column-gap: 16px;
        align-items: center;
        padding-right: 16px;
        margin-right: 16px;
        position: relative;

        &::before {
            position: absolute;
            right: 0;
            top: 4px;
            width: 1px;
            height: calc(100% - 8px);
            content: "";
            background: $alter-white-30;
            display: block;
        }
    }

    &__item {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        path {
            fill: $alter-white-30;
        }

        &.is-active {
            path {
                fill: $alter-white;
            }
        }

        &:not(.is-active) {
            &:hover {
                path {
                    fill: $alter-white-60;
                }
            }
        }
    }

    &__tooltip {
        .rc-tooltip-inner {
            background: rgb(0 0 0 / 8%);
            backdrop-filter: blur(40px);
            border-radius: 4px;
            box-shadow: none;
            max-width: unset;
        }

        &-text {
            color: $alter-white;
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.has-blur-bg {
    background: rgb(50 54 72 / 30%);

    @supports (backdrop-filter: blur(40px)) {
        background: rgb(50 54 72 / 30%);
        backdrop-filter: blur(40px);
    }
}

.access-devices {
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (width <=1300px) {
            flex-direction: column;
            align-items: start;
        }
    }

    &__icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
    }

    &__give-access {
        color: #29b981;
        cursor: pointer;
        position: relative;

        &::before {
            content: "";
            display: block;
            border-bottom: 1px solid #29b981;
            height: 1px;
            position: absolute;
            bottom: 4px;
            left: 0;
            width: 100%;
        }

        @media (width <=1300px) {
            margin-left: 32px;
        }
    }

    &__modal {
        position: absolute;
        top: 60px;
        left: 24px;
        right: 24px;
        background: #4f5262;
        border-radius: 8px;
        padding: 16px;

        a {
            color: white;
            text-decoration: underline;
        }

        @include mobile {
            position: absolute;
            top: 50px;
            left: -8px;
            right: -8px;
        }
    }

    &__default-links {
        a {
            color: $alter-white;
        }
    }

    &__link {
        /* stylelint-disable-next-line no-descending-specificity */
        path {
            transition: $alter-control-transition;
        }

        a {
            color: $alter-white-60;
            text-decoration: none;

            &:hover {
                color: var(--alter-secondary-hover-color);

                /* stylelint-disable-next-line no-descending-specificity */
                path {
                    fill: var(--alter-secondary-hover-color);
                }
            }
        }

        &-text {
            position: relative;

            &::before {
                content: "";
                display: block;
                border-bottom: 1px solid $alter-white-60;
                height: 1px;
                position: absolute;
                bottom: 2px;
                left: 0;
                width: 100%;
            }
        }
    }
}
