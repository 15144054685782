@import "../../../../../styles/tokens";
@import "../../../../../../node_modules/bulma/sass/utilities/mixins";

.user-details-form {
    &__phone-info {
        @include alter-universal-secondary;

        margin-top: -8px;
        color: $alter-dark-60;
        margin-bottom: 16px;
    }
}
