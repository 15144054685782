@import "../../../styles/tokens";
@import "../../../../node_modules/bulma/sass/utilities/mixins";

.call-notification {
    padding: 8px 16px;
    color: $alter-white;

    &__icon {
        display: none;
    }

    &__upcoming {
        background-color: $alter-primary-lite;
    }

    &__current {
        background-color: $alter-advanced-indigo;
    }

    @include tablet {
        border-radius: 8px;

        &__icon {
            display: block;
        }
    }
}
