@import "../../../../ui-kit/components-branded/Headline/styles";

.hello-header {
    &__wrapper {
        gap: 8px;
    }

    &__greeting::first-letter {
        text-transform: uppercase;
    }

    &__icon {
        height: 32px;
        width: 32px;
        @include tablet {
            height: 40px;
            width: 40px;
        }
    }
}
