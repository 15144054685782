@import "../../../ui-kit/styles-branded/colors";
@import "../../../../node_modules/bulma/sass/utilities/mixins";

.rdp.payments-date-picker {
    --rdp-accent-color: #{$alter-primary-lite};
    --rdp-background-color: #{$alter-primary-10};

    margin: 0 0 8px;

    .rdp-head_cell {
        text-align: center;
    }

    .rdp-months {
        margin-top: 32px;
        margin-bottom: 32px;
        justify-content: center;
        display: block;

        @include tablet {
            display: flex;

            & + & {
                margin-left: 1em;
            }
        }
    }

    .rdp-month {
        margin: 0;
    }

    .rdp-month + .rdp-month {
        margin-top: 16px;

        @include tablet {
            margin-top: 0;
            margin-left: 16px;
        }
    }

    .rdp-table {
        margin: 0 auto;

        @include tablet {
            margin: 0;
        }
    }

    .rdp-caption {
        max-width: calc(var(--rdp-cell-size) * 7);
        margin: 0 auto;

        @include tablet {
            max-width: unset;
            margin: 0;
        }
    }
}

.rangeDatePicker {
    &Button {
        &:disabled {
            background-color: #fff !important;
            border: 1px solid hsl(0, 0%, 86%) !important;
            box-shadow: none !important;
            opacity: 0.5 !important;
            color: $alter-dark-20 !important;
            &:hover {
                background-color: transparent !important;
                border-color: $alter-dark-60 !important;
            }
        }
    }
}
