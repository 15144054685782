@import "../../styles/tokens";
@import "../../ui-kit/styles-branded/misc";
@import "../../../node_modules/bulma/sass/utilities/mixins";

.page-header {
    background-color: var(--alter-primary-bg-color);
    color: var(--alter-control-primary-content-color);
    width: 100%;
    height: 57px;
    min-height: 57px;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: solid 1px var(--alter-separator-color);
    position: relative;

    @include tablet {
        padding-left: 24px;
        padding-right: 24px;
    }

    &__left,
    &__right {
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-basis: 100px;
    }

    &__left {
        @include alter-universal-secondary;
    }

    &__right {
        justify-content: flex-end;
    }

    &__back-arrow {
        margin-right: 4px;
    }

    &__logo {
        color: var(--alter-control-primary-content-color);
        font-weight: 500;
        font-size: 29px;
        position: absolute;
        inset: 0;
        margin: auto;
        width: 0;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: $alter-control-transition;

        &:hover {
            color: var(--alter-control-primary-content-color-hover);
            text-decoration: none;
        }
    }

    @include tablet {
        height: 65px;
    }

    &__menu-container {
        margin-left: auto;

        @include mobile {
            flex-direction: row-reverse;
        }
    }

    &__user-picture-alter-plus {
        color: $alter-advanced-indigo;
        border-color: $alter-advanced-indigo;

        &.is-adaptive {
            background-color: $alter-white;
            color: $alter-advanced-indigo;
        }
    }
}

.desktop-navigation {
    display: none;
}

.mobile-navigation {
    display: block;
}

@include tablet {
    .desktop-navigation {
        display: flex;
    }

    .mobile-navigation {
        display: none;
    }
}

a.gift-link {
    text-decoration: none;
    display: inline-flex;
    align-items: center;

    img {
        width: 21px;
        margin-right: 6px;
    }

    &.is-hoverable:hover {
        .gift-link__target {
            text-decoration: underline;
            color: var(--alter-secondary-color-header-nav-link);
        }
    }
}
