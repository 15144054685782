@import "variables";
@import "fonts";
@import "tokens";

.filters {
    max-width: 300px;
    padding: 20px;
    margin: 0 auto 24px;
    background: #fff;

    &__block-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @media (width <= 425px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &__form-block {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-width: 0;
        padding: 10px;
        color: #000;
        font-size: 16px;
        font-weight: 300;

        &--none {
            display: none;
        }

        &--full-width {
            grid-column: 1 / span 2;

            @media (width <= 425px) {
                grid-column: unset;
            }
        }

        & label {
            margin: 2px 0 8px;
            @extend %font-futura-400;

            opacity: 0.6;
        }
    }

    &__form-item {
        display: flex;
        align-items: center;
        height: 48px;
        padding: 0 0 0 16px;
        font-size: 18px;
        background: $alter-lightgrey-background;
    }

    &__form-block-slider {
        position: relative;
        margin: 55px auto 0;
    }

    &__form-checkbox {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-width: 0;
        padding: 10px;
        color: #000;
        font-size: 16px;
        font-weight: 300;

        & label {
            margin: 2px 0 8px;
            @extend %font-futura-400;
        }

        &--emoji {
            margin-left: 3px;
            height: 18px;
            width: 18px;
        }
    }

    &__slider-title {
        margin: -35px 0 15px;
        font-size: 16px;
        @extend %font-futura-400;

        color: #000;
        opacity: 0.5;
    }

    &__range-container {
        margin: 0 0 -10px;
    }

    &__specialization {
        position: relative;
    }

    &__reset {
        position: absolute;
        top: 35px;
        left: 54%;
        width: 140px;
        @extend %font-futura-400;

        color: $alter-green-text;
        transition-property: color;
        transition-duration: 1s;
        transition-timing-function: linear;

        &:hover,
        &:focus {
            color: $alter-green-focus;
        }

        &:active {
            color: $alter-green-active;
        }
    }

    &__link-container {
        display: flex;
        justify-content: center;
        margin: 0 auto 27px;
    }

    &__link-more {
        font-size: 18px;
        @extend %font-futura-400;

        color: $alter-green-text;
        text-transform: initial;
        border-bottom: 3px dotted $alter-green-text;
        animation-duration: 0.8s;
        transition-property: color;
        transition-duration: 1s;
        transition-timing-function: linear;

        &:hover,
        &:focus {
            color: $alter-green-focus;
            border-bottom: 3px dotted $alter-green-focus;
        }

        &:active {
            color: $alter-green-active;
            border-bottom: 3px dotted $alter-green-active;
        }

        &--hide {
            display: none;
        }
    }

    &__block-more-container {
        display: flex;
        margin: -56px 0 0;
        animation-name: emersion;
        animation-duration: 0.8s;
        transition: display 2s cubic-bezier(0, 0, 1, 1) 500ms;

        &--hide {
            display: none;
        }
    }

    &__form-block-children {
        margin: 48px auto 27px;
    }

    &__submit {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 266px;
        height: 46px;
        margin: 0 auto;
        border: 2px solid $alter-green-text;
        border-radius: 22.5px;
        box-sizing: border-box;
        font-size: 14px;
        @extend %font-futura-400;

        color: $alter-green-text;
        background: #fff;
        letter-spacing: 1.1px;
        text-transform: uppercase;
        transition-property: background;
        transition-duration: 1s;
        transition-timing-function: linear;

        &:hover,
        &:focus {
            background: $alter-green-focus;
            border: 2px solid $alter-green-focus;
            color: #fff;
        }

        &:active {
            background: $alter-green-active;
            border: 2px solid $alter-green-active;
            color: #fff;
        }
    }
    @media (width >= 640px) {
        max-width: 620px;
        margin: 0 auto 32px;

        &__form-block-slider {
            margin: 85px auto 0;
        }
    }
    @media (width >= 954px) {
        max-width: 940px;
        padding: 14px 30px 30px;

        &__form-block {
            font-size: 18px;
        }

        &__form-item {
            font-size: 20px;
        }

        &__form-block-slider {
            margin: 55px 0 0;
            width: 200px;

            &--age {
                margin: 25px 30px 0 0;
            }
        }

        &__form-checkbox {
            font-size: 18px;
        }

        &__slider-title {
            margin: -35px 0 20px;
            font-size: 18px;
        }

        &__specialization {
            margin: -14px auto 50px 0;
        }

        &__reset {
            display: none;
        }

        &__form-block-family {
            position: absolute;
            top: -98px;
            left: 692px;
            width: 200px;
            margin: -14px 0 5px;
        }

        &__form-block-gender {
            margin: -12px 75px 5px 3px;
        }

        &__form-block-children {
            margin: -13px 48px 27px 0;
        }

        &__link-container {
            display: none;
        }

        &__submit {
            width: 246px;
            margin: 0 auto 0 315px;
        }

        &__block-more-container {
            animation-name: none;
            animation-duration: 0.8s;
        }

        &--hide {
            display: none;
        }
    }

    @include screen-size(desktop) {
        max-width: 980px;
        padding: 14px 52px 30px;
        box-shadow: 0 -10px 40px rgb(191 191 185 / 20%);

        &__form-block {
            font-size: 18px;
        }

        &__form-item {
            font-size: 20px;
        }

        &__form-block-slider {
            margin: 55px 0 0;
            width: 200px;

            &--age {
                margin: 25px 30px 0 0;
            }
        }

        &__form-checkbox {
            font-size: 18px;
        }

        &__slider-title {
            margin: -35px 0 20px;
        }

        &__specialization {
            margin: -14px auto 50px 0;
        }

        &__reset {
            display: none;
        }

        &__form-block-family {
            position: absolute;
            top: -98px;
            left: 688px;
            margin: -14px auto 5px 0;
        }

        &__form-block-gender {
            margin: -14px 75px 5px -1px;
        }

        &__form-block-children {
            margin: -14px 48px 27px 0;
        }

        &__link-container {
            display: none;
        }

        &__submit {
            width: 246px;
            margin: 0 auto 0 315px;
        }
    }

    .alert {
        margin: 0 10px;
        background-color: transparent;
        color: $alter-green-focus;
        border: none;
    }

    .price-range-text,
    .age-range-text {
        display: inline-block;
        float: right;
        font:
            14px FuturaPT,
            sans-serif;
        font-weight: 400;
        opacity: 0.6;
    }

    .dropdown-toggle::after {
        border: none;
    }

    .multiselect-menu {
        // Always show scroll bar https://stackoverflow.com/questions/7492062/css-overflow-scroll-always-show-vertical-scroll-bar
        ::-webkit-scrollbar {
            appearance: none;
            width: 7px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgb(0 0 0 / 50%);
            box-shadow: 0 0 1px rgb(255 255 255 / 50%);
        }

        max-width: 100%;
        width: 100%;
        padding: 0.5em !important;
        border: 0;
        border-radius: 0;
        margin: 0;
        background-color: $alter-white;

        .container-fluid {
            display: flex;

            input {
                margin-top: 0.25em;
            }
        }
    }

    select {
        background-color: $alter-gray-80 !important;
    }
}

.label-with-opacity {
    opacity: 0.6;
}
