@import "../../styles/tokens";

.discount {
    &__button {
        &--mt {
            margin-top: 34px;
        }
    }

    &__list {
        gap: 8px;
    }

    &__list-item {
        cursor: pointer;
    }

    &__description {
        color: $alter-dark-60;
        white-space: pre-wrap;

        &__disabled {
            color: $alter-dark-30;
        }
    }

    &__title {
        &__disabled {
            color: $alter-dark-60;
        }
    }

    &__recommend {
        position: relative;
        display: inline-block;

        &::before {
            content: "";
            display: block;
            background: $alter-primary-lite;
            height: 1px;
            position: absolute;
            bottom: 4px;
            left: 0;
            width: 100%;
        }
    }

    &__success {
        color: $alter-primary-dark;
    }

    &__error {
        color: $alter-danger-dark;
    }

    &__promocode {
        margin-bottom: -16px;
    }
}
