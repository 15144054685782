@import "../../../../../node_modules/bulma/sass/utilities/mixins";
@import "../../../../styles/tokens";
@import "../../../../ui-kit/styles-branded/misc";

.desktop-navigation {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include alter-universal-body-text-accent;

    &-submenu-item {
        a {
            display: block;
            width: 100%;
            height: 100%;
            padding: 4px 24px 8px;
        }
    }

    &-item {
        padding: 8px;
        color: var(--alter-primary-color-header-nav-link);
        @include tablet-only {
            padding: 8px 5px;
        }
    }

    &-item-primary > a {
        color: var(--alter-secondary-color-header-nav-link);
    }

    &-item,
    &-submenu {
        a {
            color: var(--alter-primary-color-header-nav-link);

            &.restore-link-hover-color {
                &:hover {
                    text-decoration: underline;
                    color: var(--alter-secondary-color-header-nav-link);
                }
            }
        }

        a.is-active {
            color: var(--alter-secondary-color-header-nav-link);
            cursor: default;
            text-decoration: none;

            &:hover {
                color: var(--alter-secondary-color-header-nav-link);
            }
        }
    }

    &-item a:hover {
        color: var(--alter-secondary-color-header-nav-link);
    }

    &-submenu a:hover {
        text-decoration: none;
        color: var(--alter-primary-color-header-nav-link);
    }

    &-item-submenu-wrapper {
        position: absolute;
        right: 20px;
        top: 50px;
        z-index: 1000;
        transition: opacity 0.3s ease-out;
        opacity: 0;
        height: 0;
        padding: 0;
        overflow: hidden;
        box-shadow: $box-shadow;
        border-radius: 8px;
    }

    &-item-submenu {
        svg path {
            fill: var(--alter-primary-icon-color);
        }
    }

    &-item-submenu:hover &-item-submenu-wrapper {
        display: initial;
        opacity: 1;
        height: auto;
    }

    &-submenu {
        width: 464px;
        background-color: var(--alter-secondary-bg-color);
        border-radius: 8px;
        cursor: default;
        padding: 0 0 16px;

        @include alter-universal-body-text;
    }

    &-submenu-special {
        padding: 0 24px;
        display: flex;
        align-items: center;
        height: 56px;
    }

    &-submenu-special-divider {
        color: $alter-dark-30;
        padding: 0 8px;
    }

    &-submenu-divider {
        color: var(--alter-separator-color);
        height: 1px;
        margin: 0;
    }

    &-submenu-columns {
        display: flex;
    }

    &-submenu-column {
        width: 232px;
    }

    &-submenu-header {
        color: var(--alter-headline-text-color);
        padding: 12px 24px 4px;
        @include alter-universal-body-text-accent;
    }

    &-submenu-block-divider {
        color: $alter-gray;
        height: 1px;
        margin: 8px 24px;
    }

    &-submenu-item:hover {
        background-color: $alter-primary-10;
    }

    &-submenu-item-primary {
        @include alter-universal-body-text-accent;
    }
}
