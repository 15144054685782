.abcRioButton {
    height: 36px;
    min-width: 180px;
    border-radius: 1px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
    box-sizing: border-box;
    transition:
        background-color 0.218s,
        border-color 0.218s,
        box-shadow 0.218s;
    user-select: none;
    appearance: none;
    background-color: #fff;
    background-image: none;
    color: #262626;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    margin: 18px;

    &:focus {
        :not(:focus-visible) {
            outline: none;
        }
    }

    &:hover {
        box-shadow: 0 0 3px 3px rgb(66 133 244 / 30%);
    }
}

.abcRioButtonBlue {
    background-color: #4285f4;
    border: none;
    color: #fff;

    &:hover {
        background-color: #4285f4;
    }

    &:active {
        background-color: #3367d6;
    }
}

.abcRioButtonRed {
    background-color: #ff4245;
    border: none;
    color: #fff;

    &:hover {
        background-color: #ff4245;
    }

    &:active {
        background-color: #cc0709;
    }
}

.abcRioButtonLightBlue {
    background-color: #fff;
    color: #757575;

    &:active {
        background-color: #eee;
        color: #6d6d6d;
    }
}

.abcRioButtonIcon {
    float: left;
    background-color: #fff;
    border-radius: 1px;
    padding: 8px;
}

.abcRioButtonIconImage {
    width: 18px;
    height: 18px;
}

.abcRioButtonSvg {
    display: block;
}

.abcRioButtonContents {
    line-height: 34px;
    font-family: Roboto, arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.21px;
    margin-left: 6px;
    margin-right: 6px;
    vertical-align: top;
}

.abcRioButtonContentWrapper {
    height: 100%;
    width: 100%;
}

.abcRioButtonBlue .abcRioButtonContentWrapper,
.abcRioButtonRed .abcRioButtonContentWrapper {
    border: 1px solid transparent;
}

.abcRioButtonErrorWrapper,
.abcRioButtonWorkingWrapper {
    display: none;
    height: 100%;
    width: 100%;
}

.abcRioButtonErrorIcon,
.abcRioButtonWorkingIcon {
    margin-left: auto;
    margin-right: auto;
}

.abcRioButtonErrorState,
.abcRioButtonWorkingState {
    border: 1px solid #d5d5d5;
    border: 1px solid rgb(0 0 0 / 17%);
    box-shadow: 0 1px 0 rgb(0 0 0 / 5%);
    color: #262626;

    &:hover {
        border: 1px solid #aaa;
        border: 1px solid rgb(0 0 0 / 25%);
        box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
    }

    &:active {
        border: 1px solid #aaa;
        border: 1px solid rgb(0 0 0 / 25%);
        box-shadow: inset 0 1px 0 #ddd;
        color: #262626;
    }
}

.abcRioButtonWorkingState,
.abcRioButtonWorkingState:hover {
    background-color: #f5f5f5;
}

.abcRioButtonWorkingState:active {
    background-color: #e5e5e5;
}

.abcRioButtonErrorState,
.abcRioButtonErrorState:hover {
    background-color: #fff;
}

.abcRioButtonErrorState:active {
    background-color: #e5e5e5;
}

.abcRioButtonWorkingState .abcRioButtonWorkingWrapper,
.abcRioButtonErrorState .abcRioButtonErrorWrapper {
    display: block;
}

.abcRioButtonErrorState {
    filter: grayscale(1);
    cursor: not-allowed;
}
