@use "sass:math";
@import "variables";
@import "fonts";
@import "colors";
@import "tokens";
@import "functions";

html {
    box-sizing: border-box;
    height: 100%;
}

.profile {
    &__sticky-panel-video {
        & > iframe {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
    }
}

.labels {
    display: flex;
    gap: 8px;
    padding-top: 16px;
    flex-wrap: wrap;

    &__label {
        background-color: $alter-advanced-yellow-20;
        color: rgb(50 54 72 / 60%);
        font-size: 16px;
        height: 24px;
        line-height: 24px;
        border-radius: 4px;
        white-space: nowrap;
        padding-left: 4px;
        padding-right: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__desktop {
        display: none;
        flex-wrap: wrap;
        margin-top: 20px;
        max-width: 400px;
        @include screen-size(desktop) {
            display: flex;
        }

        .labels__label {
            margin-bottom: 8px;
            margin-right: 8px;
        }
    }

    &__mobile {
        display: flex;
        flex-wrap: nowrap;
        width: max-content;
        padding-top: 15px;

        @include screen-size(desktop) {
            display: none;
        }

        .labels__label {
            margin-bottom: 8px;
            margin-right: 8px;
        }

        &-container {
            width: 100%;
            overflow-x: scroll;
            border-top: 1px solid $alter-gray;
            margin-top: 27px;
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
            @include screen-size(desktop) {
                display: none;
            }
        }

        &__overflow-gradient {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 1px;
                right: 0;
                width: 63px;
                background: linear-gradient(270deg, #fff 3.75%, rgb(255 255 255 / 0%) 97.5%);
                pointer-events: none;
            }
        }
    }

    &__emoji {
        width: 17px;
        height: 17px;
        margin-right: 4px;
        vertical-align: -3px;
    }

    &__emoji_21x21 {
        width: 21px;
        height: 21px;
        margin-right: 4px;
        vertical-align: -3px;
    }
}

.tabs {
    &--item {
        font-size: 20px;
        margin-right: 32px;
    }
}
