@import "../../styles-branded/colors";
@import "../../styles-branded/misc";

.select {
    display: inline-flex !important;

    select {
        width: 100%;
    }

    &.blm-autocomplete {
        padding-top: 0;
        padding-left: 0;
        padding-bottom: 0;
        border: solid transparent 1px;

        &.is-hovered {
            path {
                fill: $alter-primary-lite;
            }
        }
    }
}

.blm-autocomplete {
    &__input.input {
        border-color: transparent;
        background-color: transparent;
        box-shadow: none;
        transition: $alter-control-transition;

        &:hover,
        &.is-active {
            border-color: $alter-dark-20;
            background-color: $alter-white;
        }
    }

    &__root {
        width: inherit;
    }

    &__clear-text {
        color: $alter-dark-60;
    }

    &__not-found {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

.blm-dropdown {
    &.is-active {
        .blm-autocomplete {
            &__root {
                box-shadow: 0 0 0 1px $alter-primary-lite;
                border-radius: 8px;
            }
        }
    }
}
