@import "../../styles/tokens";

.bad-illustration {
    width: 344px;
    height: 195px;
    @media (width >= 768px) {
        width: 564px;
        height: 320px;
    }
}

.not-found-page {
    display: flex;
    flex-direction: column;
    color: $alter-dark;
    max-width: 342px;
    margin-left: 16px;
    margin-right: 16px;
    @media (width >= 768px) {
        max-width: 100%;
    }

    &__headline {
        margin-top: 24px;
        margin-bottom: 32px;
        text-align: center;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        @media (width >= 768px) {
            margin-top: 56px;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        @media (width >= 768px) {
            flex-direction: row;
        }
    }

    &__secondary-button {
        line-height: 14px;
        margin-top: 15px;
        @media (width >= 768px) {
            margin-top: 0;
            margin-left: 16px;
        }
    }
}
