.header {
    &__navigation-wrapper {
        display: flex;
        align-items: center !important;
        justify-content: right;
        flex: 1 1 auto;
    }
}

.psy-header {
    margin-bottom: 20px;
}
