@import "../../styles/colors";
@import "../../styles/tokens";

.course-banner {
    background: #458bf5;
    height: 128px;
    width: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    &__title {
        position: absolute;
        border-radius: 16px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 20px;
        left: 14px;
        background: $alter-white-60;
        padding: 0 8px;
        color: $alter-dark;
        z-index: 1;
    }

    &__description {
        top: 52px;
        left: 14px;
        position: absolute;
        color: $alter-white;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        z-index: 1;
    }

    &__camera {
        width: 23px;
        position: relative;
        top: -4px;
        margin-left: 5px;
    }

    &__book {
        width: 19px;
        position: relative;
        top: -2px;
        margin-left: 2px;
    }

    &__clearCalendar {
        width: 18px;
        position: relative;
        top: -3px;
        margin-left: 4px;
    }

    &__present {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 106px;
        height: 128px;
    }
}
