@import "../../../../styles/tokens";

.booking-heading {
    font-weight: 500;
    padding: 0;
    color: rgb(50 54 72 / 100%);
    font-size: 24px;
    margin-left: 16px;
    margin-right: 16px;
    @include screen-size(desktop) {
        font-size: 30px;
        line-height: 32px;
    }

    &-text {
        padding-left: 8px;
        @include screen-size(desktop) {
            padding-left: 16px;
        }

        &--after-payment {
            padding-left: 0;
            display: flex;
            align-items: center;
        }
    }

    &__container {
        width: 100%;
        margin: 24px 0;

        @include screen-size(desktop) {
            padding: 33px 0;
            background: $alter-gray-50;
            margin-bottom: 56px;
            margin-top: 0;
        }
    }

    &__avatar {
        &-container {
            display: flex;
            align-items: center;
            font-weight: 500;
            @include screen-size(desktop) {
                line-height: 38px;
            }
        }
    }
}
