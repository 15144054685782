@import "../../../styles/tokens";
@import "../../../../node_modules/bulma/sass/utilities/mixins";

.user-data-container {
    display: flex;
    margin: 16px 0 40px;
    width: 100%;
    gap: 32px;
    @include alter-universal-body-text;

    @include mobile {
        flex-direction: column;
        align-items: center;
    }

    &__left {
        flex: 1;
        text-align: left;
    }
}
