@mixin btn-base {
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: 18px;
    letter-spacing: 0;
    padding: 12px 16px;
    border-radius: 8px;
    border: none;
    min-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
