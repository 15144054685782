@import "../../../../styles/tokens";
@import "../../../../../node_modules/bulma/sass/utilities/mixins";

.booking-contacts-client {
    &__name {
        font-weight: 500 !important;
        display: flex;
        flex-wrap: wrap;
    }

    &__telephone {
        margin-bottom: 8px;
    }

    &__contacts {
        color: $alter-dark-60;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    &__dots {
        display: none;
        width: 18px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 5px;
            height: 5px;
            background: $alter-dark-30;
            border-radius: 50%;
        }
    }

    &__agreement {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: #323648;
    }
}

@include tablet {
    .booking-contacts-client {
        &__telephone {
            margin-bottom: 0;
        }

        &__contacts {
            flex-direction: row;
            flex-wrap: nowrap;
        }

        &__dots {
            display: block;
        }
    }
}
