@import "../../styles/tokens";
@import "../../../node_modules/bulma/sass/utilities/mixins";

.notification-banner {
    &__wrapper {
        width: 100%;
    }

    @include tablet {
        &__wrapper {
            width: auto;
            position: fixed;
            top: 80px;
            right: 16px;
            z-index: 200;
        }
    }
}
