@import "../../../../ui-kit/styles-branded/colors";
@import "../../../../styles/tokens";

.one-card-item {
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__plus-icon {
        margin-right: 15px;
    }

    &__last-card-digits {
        @include alter-universal-body-text;

        flex: 1;
        margin-left: 9px;
        color: $alter-dark-60;
    }
}
