@import "../../styles/colors";
@import "../../styles/tokens";

.booking {
    &__heading {
        &-text {
            padding-left: 8px;
            @include screen-size(desktop) {
                padding-left: 16px;
            }

            &--after-payment {
                padding-left: 0;
                display: flex;
                align-items: center;
            }
        }

        &-container {
            width: 100%;
            margin: 24px 0;

            @include screen-size(desktop) {
                padding: 33px 0;
                background: $alter-gray-50;
                margin-bottom: 56px;
                margin-top: 0;
            }
        }

        &__avatar {
            border-radius: 50%;
            width: 32px;
            height: 32px;
            @include screen-size(desktop) {
                width: 40px;
                height: 40px;
            }

            img {
                border-radius: 50%;
                min-width: 100%;
                min-height: 100%;
            }

            &-container {
                display: flex;
                align-items: center;
                font-weight: 500;
                @include screen-size(desktop) {
                    line-height: 38px;
                }
            }
        }
    }

    &__primary-button {
        color: $alter-white;
        background-color: $alter-primary-lite;
        border-radius: 8px;
        text-align: center;
        padding: 16px 32px;
        font-weight: 500;
        width: 100%;
        font-size: 18px;
        line-height: 18px;

        &:focus {
            :not(:focus-visible) {
                outline: none;
            }
        }

        &:hover {
            background-color: $alter-primary-dark;
        }
    }

    &__emoji {
        height: 24px;
        width: 24px;
        margin-right: 4px;

        &--is-calendar {
            margin-top: -1px;
        }

        &.is-lock {
            margin-top: -2px;
        }
    }

    &__big-emoji {
        height: 24px;
        width: 24px;
        margin-right: 8px;
        @include screen-size(desktop) {
            height: 32px;
            width: 32px;
        }
    }

    &__small-emoji {
        height: 18px;
        width: 18px;
        margin-right: 8px;

        &--is-time-request {
            margin-right: 4px;
            margin-left: 3px;
        }
    }

    &__error-text {
        @include alter-universal-body-text;

        color: $alter-danger-lite;
        margin-bottom: 6px;

        &:last-child {
            margin-bottom: 0;
            margin-top: 6px;
        }
    }

    &__error-support-tooltip-image {
        position: relative;
        top: -1px;
        left: 5px;
    }

    &__fixed-next-button {
        box-shadow: $box-shadow;
        padding: 8px 16px;

        &.bottom-fixed {
            @media (width <= 991px) {
                display: block;
            }
        }
    }
}
