@import "../../styles/tokens";

.price-and-discount {
    &__discount-strikethrough {
        font-size: 16px;
        font-weight: 400;
        color: $alter-dark-60;
        position: relative;

        &::after {
            position: absolute;
            content: "";
            top: 40%;
            left: -5%;
            width: 110%;
            height: 1px;
            background: $alter-danger-lite;
            transform: rotate(349deg);
        }
    }
}
