.date-time-container {
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }

    @include mobile {
        position: relative;
    }
}

.date-time-dropdown {
    .date-time-container {
        gap: 8px;

        @include mobile {
            width: 100%;
        }
    }

    &__wrapper {
        flex: 1;

        @include mobile {
            width: 40%;
        }

        .dropdown-trigger,
        .drop-content {
            width: 100%;
        }

        .dropdown {
            width: 100%;
        }

        .select.blm-select {
            &::before {
                background-color: #fff;
                border-radius: 8px;
            }

            &::after {
                display: none !important;
            }
        }

        .select.blm-select.is-hovered::before,
        .select.blm-select.is-active::before,
        .select.blm-select.is-focused::before {
            background-color: #fff;
        }

        .select.blm-select.is-disabled {
            color: $alter-dark-20;
        }

        .dropdown-menu {
            width: 100%;
        }

        .dropdown-item {
            font-size: 18px;
            padding: 12px 16px !important;
        }

        &__arrow {
            position: absolute;
            right: 23px;
            top: 14px;

            path {
                fill: $alter-dark-30;
            }
        }
    }
    @include mobile {
        .dropdown {
            position: static;
        }

        .dropdown-menu {
            margin-top: 8px;
            padding: 0;
            width: calc(100% - 8px);
            @include mobile {
                margin-bottom: 10px;
            }
        }

        .dropdown-content {
            width: 100%;
            padding: 0;
        }
    }

    &__item-hour {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__hour-notify {
        color: $alter-primary-dark;
        flex: 1;
        text-align: right;
    }

    &__emoji {
        height: 24px;
        width: 24px;
        margin-left: 4px;
        align-self: flex-start;
    }

    .blm-select__wrapper {
        position: static;
    }
}
