@import "variables";
@import "fonts";
@import "filters";
@import "base";
@import "common";
@import "tokens";

.search-results-redesign {
    background-color: #fcfcfb !important;
}

.search-results {
    position: relative;
    @extend %font-futura-600;

    font-size: 16px;
    background-color: $alter-gray-20;

    &__container {
        flex: 1 0 auto;
        width: 100%;
        max-width: 980px;
        margin: 0 auto;
    }

    .header {
        @include header;

        background-color: transparent;
    }

    @include questions;

    .discount-info-box {
        background-image: linear-gradient(white 50%, rgb(255 255 255 / 45%));
        color: $alter-online-booking-orange;
        font-size: 20px;
        text-align: center;
        padding: 1em;
        margin: 0 auto 1em;
        max-width: 300px;
        @media (width >= 640px) {
            max-width: 620px;
        }
        @media (width >= 954px) {
            max-width: 980px;
        }
    }

    .psychologist-list {
        margin-bottom: 20px;

        @media (width >= 640px) {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 20px;
        }
        @media (width >= 950px) {
            gap: 20px 40px;
        }
    }

    .psychologist-card {
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        max-width: 300px;
        box-shadow: 0 -10px 40px 0 rgb(191 191 185 / 20%);
        background-color: #fff;
        margin: 0 auto 20px;
        border-radius: 8px;

        @media (width >= 640px) {
            margin: 0;
        }

        .buttons-on-card {
            margin: 0 auto;
            width: 210px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1.4px;
        }
    }

    .psychologist {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 15px;
        text-decoration: none;

        &__avatar {
            margin-bottom: 5px;
        }

        &__title {
            font-weight: 600;
            font-size: 24px;
            color: $alter-green-text;
            margin: 0 0 5px;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover,
            &:focus {
                color: $alter-dark-green-focus;
            }

            &:active {
                opacity: 0.7;
            }
        }

        &__title-alter-plus {
            color: $alter-plus-1;

            &:hover,
            &:focus {
                color: $alter-plus-2;
            }
        }

        &__subtitle {
            display: block;
            color: $alter-black-60;
            text-align: center;
            margin: 0 0 14px;
            font-size: 16px;
            font-weight: 400;
        }

        .benefits__list {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            list-style: none;
            border-top: 1px solid rgb(151 151 151 / 20%);
            border-bottom: 1px solid rgb(151 151 151 / 20%);
            padding: 12px 0;
            margin: 0 0 14px;

            &-item {
                display: flex;
                justify-content: center;
                color: black;

                &-text {
                    padding-left: 5px;
                    padding-right: 5px;
                    margin: 0;

                    &--title {
                        text-align: center;
                        font-size: 12px;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        margin-bottom: 5px;
                    }

                    &--subtitle {
                        text-align: center;
                        @extend %font-futura-400;

                        font-size: 17px;
                        opacity: 0.6;
                    }

                    &--price {
                        text-align: center;
                        @extend %font-futura-400;

                        font-size: 17px;
                    }
                }
            }
        }

        &__details-container {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &__details {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 20px;
            }

            &-title {
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 0.1em;
                color: black;
                margin: 0 0 2px;
            }
        }

        .details__list {
            line-height: normal;
            list-style: none;
            padding: 0;
            margin: 0;

            @include screen-size(desktop) {
                margin-bottom: 10px;
            }

            &-item {
                position: relative;

                @extend %font-futura-400;

                font-size: 17px;
                color: $alter-black-60;
                padding-left: 10px;

                &::before {
                    position: absolute;
                    content: unicode("2022");
                    left: -6px;
                    top: 0;
                    display: inline-block;
                    color: $alter-green-marker;
                }

                &--more {
                    padding: 0;

                    &::before {
                        display: none;
                    }

                    &-button {
                        font-size: 16px;
                        font-weight: 500;
                        color: $alter-green-text;
                        background-color: transparent;
                        border: none;
                        border-bottom: 1px dotted $alter-green-text;
                        cursor: pointer;
                        outline: none;
                        padding: 0 0 2px;
                        margin: 0 auto 5px 15px;

                        &:hover,
                        &:focus {
                            color: $alter-dark-green-focus;
                        }

                        &:active {
                            opacity: 0.7;
                        }

                        @include screen-size(desktop) {
                            display: none;
                        }
                    }

                    &-list {
                        max-height: 0;
                        overflow: hidden;
                        opacity: 0;
                        transition:
                            max-height 0.1s,
                            opacity 0.3s;
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        @include screen-size(desktop) {
                            max-height: unset;
                            overflow: unset;
                            opacity: unset;
                            transition: unset;
                        }

                        &-show {
                            max-height: 1000px;
                            overflow: visible;
                            opacity: 1;
                            transition:
                                max-height 0.3s,
                                opacity 0.3s;

                            @include screen-size(desktop) {
                                max-height: unset;
                                overflow: unset;
                                opacity: unset;
                                transition: unset;
                            }
                        }
                    }
                }
            }

            &--without-style {
                .details__list-item {
                    padding-left: 0;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    .catalog-card {
        height: 270px;
        padding: 25px 20px 30px;
        margin-bottom: auto;
        background-color: white;
        box-shadow: 0 -10px 40px 0 rgb(191 191 185 / 20%);
        text-decoration: none;

        &__title {
            width: 100%;
            text-align: left;
            padding-right: 100px;
            margin-bottom: 15px;
        }

        &__subtitle {
            text-align: left;
        }
    }

    .catalog-card .psychologist__buttons-wrapper {
        padding-left: 0;
        padding-right: 0;
        margin-top: unset;
    }

    h2 {
        margin-bottom: 4px;
        width: 100%;
        padding: 0 15px;
    }
}

.advanced-search {
    &-button {
        font-size: small;
        color: $alter-green-focus;
        cursor: pointer;

        &:focus {
            :not(:focus-visible) {
                outline: none;
            }
        }
    }

    &-block {
        margin: 16px 0;
    }

    &-explanation {
        font-weight: 400;
        margin-bottom: 10px;
        text-align: justify;

        strong {
            font-weight: 600;
        }
    }

    &-collapsed {
        display: none;
    }
}
