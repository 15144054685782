@import "../../../../../ui-kit/styles-branded/colors";
@import "../../../../../ui-kit/styles-branded/misc";
@import "../../../../../../node_modules/bulma/sass/utilities/mixins";

.card-list-item {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $alter-gray-80;
    border-radius: 8px;
    padding: 0 16px 0 8px;

    &__card-icon {
        display: flex;
        align-items: center;
        width: 24px;
        justify-content: center;
        margin-right: 14px;
    }

    &__last-card-digits {
        @include alter-universal-body-text;

        flex: 1;
    }

    &__more-menu {
        cursor: pointer;

        & svg path {
            transition: $alter-control-transition;
        }

        &:hover svg path {
            fill: $alter-dark;
            fill-opacity: 0.6;
        }

        & span {
            display: block;
        }

        &.is-active svg path {
            fill: $alter-dark;
            fill-opacity: 0.6;
        }

        & .dropdown-menu {
            @include mobile {
                right: 0;
                left: auto;
            }
        }
    }

    &__remove-card {
        @include alter-universal-body-text;

        cursor: pointer;
        padding: 12px 16px;
        width: 296px;
        border-radius: 8px;

        &:hover {
            background-color: $alter-primary-10;
        }
    }
}

.remove-card-modal {
    &__title {
        margin-bottom: 24px;

        @include mobile {
            margin-bottom: 16px;
        }
    }

    &__text-block {
        margin-bottom: 56px;

        @include mobile {
            flex: 1;
        }
    }

    // &__close-icon {
    //     position: absolute;
    //     top: 21px;
    //     right: 21px;
    //     padding: 0;
    // }

    &__button-block {
        display: flex;
        gap: 16px;

        &__button {
            flex: 1;
        }

        @include mobile {
            flex-direction: column;
        }
    }
}
