@import "misc";

a,
.is-like-link.blm-button.button {
    transition: $alter-control-transition;

    &.link-secondary {
        text-decoration: underline;
        color: var(--alter-secondary-text-color);

        &:hover,
        &:active {
            color: var(--alter-secondary-hover-color);
        }
    }

    &.link-secondary-dark {
        text-decoration: underline;
        color: var(--alter-primary-text-color);

        &:hover,
        &:active {
            color: var(--alter-primary-link-color-hover);
        }
    }

    &.link-primary {
        text-decoration: underline;
        color: var(--alter-primary-link-color);

        &:hover,
        &:active {
            color: var(--alter-primary-link-color-hover);
        }
    }
}
