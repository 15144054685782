@import "../../styles/tokens";
@import "../../../node_modules/bulma/sass/utilities/mixins";
@import "../../ui-kit/styles-branded/typography";

.profile-card {
    &__card {
        height: 100%;
    }

    &__divider {
        height: 1px;
    }

    $safe-shadow-gap: 48px;

    &__header,
    &__main-info {
        align-items: flex-start;
        @include tablet {
            align-items: center;
        }
    }

    &__container-header {
        font-weight: $weight-medium;
        padding-left: 16px;
        margin-bottom: -1 * $safe-shadow-gap;

        @include tablet {
            padding-left: 24px;
        }
    }

    &__specialization {
        white-space: initial;
        text-align: center;
    }

    &__price,
    &__discount {
        line-height: 23px;
    }

    &__title {
        text-align: center;
    }

    &__labels {
        @include mobile {
            justify-content: flex-start;
        }
    }
}
