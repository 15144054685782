@import "../../styles/colors";
@import "../../styles/tokens";

@mixin dinamic-height-transition {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.8s cubic-bezier(0, 1, 0, 1) -0.1s;

    &-show {
        max-height: 9999px;
        transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
        transition-delay: 0s;
    }
}

.small-icon {
    display: none;
    @media (width <= 768px) {
        display: block;
    }
}

.normal-icon {
    display: none;
    @media (width >= 768px) {
        display: block;
    }
}

.faq {
    background-color: white;
    padding: 8px 24px;
    box-shadow: $box-shadow;
    border-radius: 8px;

    &-headline {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: $alter-dark;
        margin-bottom: 32px;
        @media (width >= 768px) {
            margin-bottom: 24px;
        }
    }

    &__item {
        padding: 16px 0;
        border-bottom: 1px solid color-get("gray", 2);
        line-height: 24px;
        cursor: pointer;

        &:last-child {
            border: none;

            .faq__secondary-items {
                .faq__secondary-item {
                    &:last-child {
                        border: none;
                    }
                }
            }
        }

        &-question {
            font-size: 18px;
            font-weight: 500;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (width >= 768px) {
                font-size: 20px;
            }
        }

        &-answer {
            @include dinamic-height-transition;

            font-weight: 400;
            font-size: 18px;

            &-margin {
                margin-top: 16px;
            }
        }

        &-opened-secondary-item {
            border: none;
        }
    }

    &__secondary-items {
        @include dinamic-height-transition;

        margin-top: 16px;
        margin-bottom: -16px;

        &-margin {
            margin-top: 16px;
        }

        &-show {
            border-top: 1px solid color-get("gray", 2);
        }
    }

    &__secondary-item {
        padding: 15px 0 16px;
        margin: 0 0 0 24px;
        border-bottom: 1px solid color-get("gray", 2);
        line-height: 24px;
        cursor: pointer;

        &-question {
            font-size: 18px;
            font-weight: 400;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (width >= 768px) {
                font-size: 20px;
            }
        }

        &-answer {
            @include dinamic-height-transition;

            font-weight: 400;
            font-size: 18px;
            cursor: default;

            &-margin {
                margin-top: 16px;
            }
        }
    }
}
