@import "../../../../ui-kit/styles-branded/colors";
@import "../../../../styles/tokens";
@import "../../../../ui-kit/styles-branded/dimensions";
@import "../../../../../node_modules/bulma/sass/utilities/mixins";
@import "../../../../ui-kit/components-branded/Headline/styles";

.past-sessions {
    &__headline {
        @include alter-universal-body-text-accent;

        margin-bottom: 24px;

        @include desktop {
            @include headline3-bold;

            margin-bottom: 32px;
        }
    }

    &__load-more-button {
        margin-bottom: 24px;

        @include desktop {
            margin-bottom: 32px;
        }
    }
}

.sessions__go-to-payment {
    background-color: $alter-advanced-yellow;
    margin: -24px -24px 16px;
    border-radius: 8px 8px 0 0;

    @include tablet {
        margin: -24px -32px 16px;
    }

    @include desktop {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &--smaller-padding {
        padding: 8px 24px 16px;
        @include desktop {
            padding: 8px 16px;
        }
    }

    &--bigger-padding {
        padding: 8px 24px;
        @include desktop {
            padding: 16px;
        }
    }

    &__text {
        @include alter-universal-body-text;

        @include desktop {
            @include alter-universal-body-text-accent;
        }
    }

    &__button {
        width: 100%;
        margin-top: 6px;
        @include desktop {
            width: auto;
            margin-top: 0;
        }
    }
}

.sessions__card {
    &__hr {
        height: 1px;
        margin: 0;

        &__not-mobile {
            @include mobile {
                opacity: 0;
            }
        }
    }

    &__header {
        color: $alter-dark;

        path {
            fill: $alter-dark;
        }

        &:hover {
            cursor: pointer;
            text-decoration: none;
            color: $alter-primary-dark;

            path {
                fill: $alter-primary-dark;
            }
        }
    }

    &__avatar {
        height: 48px;
        min-width: 48px;
        max-width: 48px;
        display: flex;

        &.is-image {
            &::before {
                width: 48px;
                height: 48px;
                position: absolute;
                z-index: 1;
                border-radius: 50%;
                content: "";
                box-shadow: inset 0 0 0 1px $alter-dark-20;
            }

            img {
                border-radius: 50%;
                min-height: 100%;
                min-width: 100%;
            }
        }
    }

    &__main-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        line-height: 22px;

        &__title {
            @include alter-universal-body-text;

            color: $alter-dark-60;
            overflow: hidden;
            text-overflow: ellipsis;

            &::first-letter {
                text-transform: capitalize;
            }
        }
    }

    &__text-wrapper {
        padding: 0 16px;
        overflow: hidden;
    }

    &__half {
        @include desktop {
            width: 100%;

            &:first-child {
                border-right: 1px solid $alter-gray;
                padding-right: 23px;
            }

            &:last-child {
                padding-left: 23px;
            }
        }
    }

    &__secondary-button-link {
        display: block;
        font-size: 18px;
        padding: 0;
        color: $alter-dark;
        @include desktop {
            text-decoration: underline;
        }
    }

    &__button-wrapper {
        width: 100%;
        text-decoration: none;
        @include desktop {
            width: auto;
        }
    }
}

.sessions__location {
    &__alter-video-call {
        display: flex;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
            align-items: center;
        }

        &__text {
            color: $alter-dark-60;
            margin-left: 0;
            margin-top: 16px;

            @include desktop {
                margin-left: 16px;
                margin-top: 0;
            }
        }
    }

    &__offline {
        display: flex;
        flex-direction: column;

        @include desktop {
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;
        }

        &__text {
            margin-bottom: 16px;
            @include desktop {
                margin-bottom: 0;
                display: flex;
                width: 60%;
                margin-left: 16px;
            }
        }
    }

    &__online {
        display: flex;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
            align-items: center;
        }
    }
}

.sessions__emoji {
    display: inline-block;
    height: 24px;
    width: 24px;
    margin-top: -5px;
    margin-right: 4px;

    &-small {
        display: inline-block;
        height: 18px;
        width: 18px;
        margin-right: 6px;
        margin-top: -4px;
    }

    &-medium {
        display: inline-block;
        height: 24px;
        width: 24px;
        margin-right: 6px;
        margin-top: -4px;
    }

    &-clock {
        display: inline-block;
        height: 24px;
        width: 24px;
        padding: 3px;
    }
}

.sessions__phone {
    color: $alter-dark;
    text-decoration: underline;
    white-space: nowrap;
}

.sessions__arrow {
    margin-left: 6px;
}
