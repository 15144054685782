@import "../../ui-kit/styles-branded/colors";
@import "../../ui-kit/styles-branded/misc";
@import "../../../node_modules/bulma/sass/utilities/mixins";

@mixin inverted {
    color: $alter-white;
    background-color: var(--alter-control-primary-content-color);
}

.user-avatar {
    background-color: var(--alter-control-default-bg-color);
    border: solid 2px var(--alter-user-avatar-border-color);
    color: var(--alter-control-primary-content-color);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    transition: $alter-control-transition;
    font-weight: 450;

    &.is-adaptive {
        @include tablet {
            @include inverted;
        }
    }

    &.is-inverted {
        @include inverted;
    }

    &.is-link {
        &:hover {
            border-color: $alter-primary-lite;
            text-decoration: none;

            @include tablet {
                color: $alter-white;
                background-color: $alter-primary-dark;
            }
        }
    }
}
