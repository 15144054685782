@import "../../styles/tokens";
@import "../../ui-kit/styles-branded/dimensions";
@import "../../../node_modules/bulma/sass/utilities/mixins";
@import "../../styles/functions";

.client-account {
    &__header {
        flex-grow: 0;
        &.container.is-fluid {
            padding-left: 16px;
            padding-right: 16px;
            background-color: $alter-gray-80;
        }
        @include tablet {
            max-height: 200px;
        }
        &.is-user-info-page {
            background-color: $alter-gray-80;
            padding-bottom: 24px;
        }
    }

    &__header-headline {
        margin-top: 24px;
        margin-bottom: 16px;
        @include tablet {
            background-color: $alter-gray-80;
            margin-top: 32px;
            margin-bottom: 32px;
        }
    }

    &__card-illustration {
        margin-top: 24px;
        margin-bottom: 16px;
    }

    &__page {
        @include mobile {
            margin: 48px 16px 0;
        }
        @include tablet {
            margin: 56px 16px 32px;
        }
        @include desktop {
            margin: 56px 0 44px;

            .columns {
                &:last-child {
                    margin-bottom: -28px;
                }
            }
        }

        &.is-selections-page {
            @include mobile {
                margin: 8px 16px -40px;
            }
        }
        &.is-user-info-page {
            @include mobile {
                margin: 24px 16px 0;
            }
        }
    }

    &__support-link {
        color: $alter-dark-60;
        text-decoration: underline;

        &:hover {
            color: $alter-dark-60;
        }
    }

    &__browser-warning {
        margin-bottom: 1.5rem;
        @include tablet {
            margin-bottom: 2rem;
        }
    }

    &__disabled-input {
        background-color: $alter-gray-80;
        border: 1px solid $alter-dark-30;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: none;
    }
}

.user-info {
    flex-direction: column;
    margin-top: 24px;
    @include tablet {
        flex-direction: row;
        margin: 56px 0;
    }

    &__avatar {
        @include tablet {
            margin-right: 24px;
        }
    }

    &__name {
        font-size: 24px;
        font-weight: 500;

        @include tablet {
            font-size: 30px;
        }
    }

    &__text.level-item {
        text-align: center;
        @include tablet {
            text-align: left;
            align-items: flex-start;
        }
    }

    &__sep::before {
        @include tablet {
            content: unicode("2022");
        }
    }

    &__contacts {
        display: inline-flex;
        flex-direction: column;
        @include tablet {
            flex-direction: row;
        }
    }
}

.media {
    &__header {
        font-weight: 500;
    }

    &__userpic {
        margin-bottom: 48px;

        @include tablet {
            margin-bottom: 16px;
        }
    }

    &__text {
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;

        &-login {
            margin-bottom: 14px;
            @media (width >= 768px) {
                margin-bottom: 0;
            }
        }
    }
}

.level.sessions {
    &:not(:last-child) {
        margin-bottom: 16px;
    }
    @include tablet {
        border-bottom: solid 1px $alter-gray;
        padding-bottom: 17px;

        &:not(:last-child) {
            margin-bottom: 31px;
        }
    }
}

.statistics {
    &__plate-wrapper {
        gap: 16px;

        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    &__plate-item {
        flex-basis: calc(50% - 8px);
        margin-bottom: 0 !important;
    }
}

.page-common {
    .notifications-tr {
        top: 8px !important;
        padding: 0 8px 10px !important;
        width: 380px !important;
        @include mobile {
            width: 100% !important;
        }
    }
    .notification.notification-warning.notification-visible {
        @include mobile {
            max-width: unset !important;
        }
    }
}
