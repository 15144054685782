@import "variables";
@import "tokens";
@import "common";
@import "fonts";

.booking-widget {
    position: relative;
    width: 100%;
    margin-top: 1rem;
    padding: 0 24px;

    @extend %font-futura-400;

    &__fetching-spinner {
        display: flex;
        justify-content: center;
        z-index: 1000;
        position: fixed;
        inset: 0;
        background-color: $alter-lightgrey-background-alpha;
    }

    .client-timezone {
        width: 100%;
        text-align: right;
        font-size: 1.4em;
        margin-bottom: 0.4em;
    }

    .react-calendar {
        width: 100%;
        margin-bottom: 1rem;
    }

    .has-available-hours {
        cursor: pointer;
    }

    .no-available-hours {
        cursor: not-allowed;
    }

    .react-calendar-tile {
        &:focus {
            :not(:focus-visible) {
                outline: none;
            }
        }
    }

    .available-hours-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-gap: 0.2em;
        width: 100%;
        text-align: center;
        font-size: 1.2em;
    }

    .available-hour {
        border-radius: 0.3em;
        background-color: $alter-green-text;
        color: white;
        padding: 0.5em;
        margin: 0.1em;
        cursor: pointer;
    }

    .back-to-calendar {
        @include link-like;

        margin-bottom: 1.4em;
    }

    .alert {
        font-size: 1.2em;
    }
}

.confirmation-form {
    @extend %font-futura-400;

    font-size: 1rem;
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.3em 1em;
    text-align: left;
    align-items: center;

    h2 {
        font-size: 1.3em;
        justify-self: center;
    }

    label {
        margin: 0.5em 0;
    }

    input {
        border-color: inherit;

        &.is-touched:invalid {
            border-color: red;
            border-width: 2px;
        }
    }

    .joined-columns {
        grid-column: 1 / 3;

        input {
            margin-right: 0.5em;
        }
    }
}

.book-button {
    display: flex;
    max-width: 230px;
    margin: 10px auto;

    &-disabled {
        color: $disabled-color;
        background-color: $disabled-background;

        &:hover {
            color: $disabled-color;
            background-color: $disabled-background;
        }
    }
}

.book-button-container {
    text-align: center;
    padding: 24px 0 0;
}

.booking-support-notice {
    @extend %font-futura-400;

    font-size: small;
    text-align: center;
    padding: 10px;
    margin-top: 35px;
    background-color: $alter-lightgrey-background;
    width: 100%;
}

.booking-ask-for-slots {
    background-color: $alter-primary-10;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.333;
    padding: 16px 24px;
    border-radius: 8px;
    margin-top: 18px;

    &__button {
        width: 100%;
        background-color: $alter-primary-lite;
        margin: 8px 0 0;

        @media (width >= 768px) {
            width: auto;
            margin: 0 0 0 8px;
        }
    }

    @media (width >= 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
