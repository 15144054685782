.Toastify__toast-container {
    z-index: 9999;
    position: fixed;
    padding: 4px;
    width: 372px;
    box-sizing: border-box;
    color: #fff;
}
.Toastify__toast-container--top-right {
    top: 2px;
    right: 4px;
}

.Toastify__toast {
    position: relative;
    line-height: 20px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 16px 40px 16px 16px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    cursor: default;
    direction: ltr;
    z-index: 0;
    background: $alter-white;
    margin-top: 8px;
    filter: drop-shadow(0px 8px 48px rgba(0, 0, 0, 0.15));
    &-body {
        color: #ffffff;
        font-size: 16px;
        line-height: 24px;
    }
    h4 {
        font-size: 18px;
        font-weight: 600;
    }
    &--default {
        .Toastify__toast-body {
            color: $alter-dark;
        }

        .Toastify__close-button {
            svg {
                fill: $alter-dark-30;
            }
        }
    }
    &--success {
        background: #29b981;
    }
    &--error {
        background: #ef6a5f;
    }
    &:nth-child(1n + 2) {
        margin-top: 18px;
    }
}

.Toastify__close-button {
    cursor: pointer;
    font-size: 17px;
    position: absolute;
    top: 16px;
    right: 16px;
    line-height: 15px;
    background-color: transparent;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-weight: bold;
    text-align: center;
    svg {
        fill: $alter-white-60;
        height: 16px;
        width: 16px;
    }
}

.Toastify--animate {
    animation-fill-mode: both;
    animation-duration: 0.7s;
}

@keyframes Toastify__bounceInRight {
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }
    75% {
        transform: translate3d(10px, 0, 0);
    }
    90% {
        transform: translate3d(-5px, 0, 0);
    }
    to {
        transform: none;
    }
}

@keyframes Toastify__bounceOutRight {
    20% {
        opacity: 1;
        transform: translate3d(-20px, 0, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(2000px, 0, 0);
    }
}

.Toastify__bounce-enter--top-right {
    animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-exit--top-right,
.Toastify__bounce-exit--bottom-right {
    animation-name: Toastify__bounceOutRight;
}

@keyframes Toastify__trackProgress {
    0% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(0);
    }
}

.Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards;
}
