@import "../../../node_modules/bulma/sass/utilities/mixins";
@import "../../ui-kit/styles-branded/colors";

.empty-header {
    max-width: 1024px;

    &__img {
        height: 1.333em;
    }
}

.whitelist-form {
    margin: auto;

    &__img {
        height: 1em;
    }

    &__container {
        width: 100%;

        @include tablet {
            width: 700px;
        }
    }

    &__sep {
        background-color: $alter-dark-30;
    }
}
