@import "../../../styles/tokens";

.header-quality-label {
    @include alter-universal-body-text;

    border: 1px solid $alter-dark-20;
    color: $alter-dark-60;
    height: 32px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    border-radius: 24px;

    &__quantity {
        color: $alter-dark;
    }

    &__quantity-red {
        color: $alter-danger-lite;
    }
}
