@import "../../../../../node_modules/bulma/sass/utilities/mixins";
@import "../../../../ui-kit/components-branded/Headline/styles";
@import "../../../../ui-kit/styles-branded/misc";

.call-page-error-contact {
    height: 100vh;
    width: 100%;
    padding: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mobile {
        width: 100%;
        padding-top: 0;
        margin-bottom: 24px;
    }

    &__content {
        margin-top: -40px;
        @include mobile {
            margin-top: 0;
        }
    }

    &__illustration {
        width: 558px;
        margin-bottom: 64px;
        @include mobile {
            width: 100%;
            padding-top: 0;
            margin-bottom: 24px;
        }
    }

    &__title {
        color: $alter-white;
        text-align: center;
        @include headline3-bold;
    }

    &__buttons {
        column-gap: 16px;
        row-gap: 16px;
        @include mobile {
            flex-direction: column;
        }
    }

    &__button {
        width: 272px;
        display: flex;
        @include mobile {
            width: 100%;
        }

        &.is-contact {
            color: $alter-white;
            border: 1px solid $alter-white-30;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            height: 48px;
            @include mobile {
                order: 1;
            }
        }

        &.is-update {
            background: $alter-primary-dark;
            @include mobile {
                order: 0;
                flex-direction: column;
            }
        }
    }

    &-modal {
        &__container {
            padding: 56px;
            width: 672px;
            background: #4f5262;
            box-shadow: $box-shadow;
            position: relative;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: $alter-white;
            @include mobile {
                width: 100%;
                overflow: auto;
                padding: 16px;
            }
        }

        &__title {
            font-size: 24px;
            line-height: 32px;
            @include mobile {
                padding-right: 24px;
            }
        }

        &__update {
            background: $alter-advanced-yellow-20;
            border-radius: 8px;
        }

        &__line {
            margin: 24px 0;
            @include mobile {
                margin: 16px 0;
            }
        }

        &__close {
            position: absolute;
            right: 21px;
            top: 21px;
            cursor: pointer;

            svg {
                rect {
                    fill: $alter-primary-dark;
                }
            }
        }
    }
}
