@import "../../ui-kit/styles-branded/typography";
@import "../../styles/tokens";

.browser-warning-banner {
    &__wrapper {
        @include default-text;

        border-radius: 8px;
        padding: 16px;
        background-color: $alter-advanced-yellow-20;
    }

    &__lite {
        color: $alter-dark;
    }

    &__dark {
        color: $alter-white;
    }

    &__header {
        @include default-text-accent;
    }

    &__inline {
        display: inline;
    }

    &__inline:nth-child(2) {
        margin-left: 4px;
    }

    &__link {
        color: inherit;
        text-decoration: underline;
    }

    &__link:hover {
        color: inherit;
    }

    &__warning {
        height: 24px;
        vertical-align: -5px;
    }
}
