@import "../../../node_modules/bulma/sass/utilities/mixins";

.header-user-picture {
    margin-right: 16px;

    @include tablet {
        margin-right: 0;
    }

    &__link:hover {
        text-decoration: none;
    }
}
