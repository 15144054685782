$block-spacing: 1.5rem;
$gap: 32px;
$tablet: 769px;
$desktop: 1024px;
$widescreen: 1152px;
$widescreen-enabled: false;
$fullhd: 1344px + (2 * $gap);
$fullhd-enabled: false;
$column-gap: 16px;
$box-padding: 24px;
$level-item-spacing: 16px;

:root {
    --alter-scrollbar-width: 0px;
}
