@import "../../../styles/tokens";
@import "../../../../node_modules/bulma/sass/utilities/mixins";

.payment-and-contact-data-block {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.booking-form {
    @include alter-universal-body-text;

    color: $alter-dark;
    display: flex;
    flex-direction: column;
    box-shadow: $box-shadow;
    border-radius: 8px;
    padding: 25px 24px;
    justify-content: center;
    height: max-content;
    background: $alter-white;

    &__headline {
        @include alter-universal-body-text-accent;
    }

    &__success-text {
        color: $alter-primary-lite;
    }

    @include screen-size(desktop) {
        width: 672px;
    }

    &__bottom-gap {
        margin-bottom: 8px;
    }

    &__error {
        color: $alter-danger-lite;
    }

    &-container {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 16px;

        @include screen-size(desktop) {
            flex-direction: row;
            margin-top: 0;
        }
    }

    &__phone {
        &-error {
            margin-top: 10px;
        }
    }

    &__email {
        &-label {
            margin-top: 16px;
        }
    }

    &__checkboxes {
        margin-left: 4px;
        color: $alter-dark-60;

        &-error {
            margin-top: 27px;
            margin-left: -3px;
        }
    }

    &__session-info {
        &__price {
            &-container {
                display: flex;
                align-items: center;
                white-space: nowrap;
                border-top: 1px solid $alter-gray;
                margin-top: 15px;
            }
        }
    }

    &__session-info-mobile {
        @include alter-universal-body-text;

        display: flex;
        flex-direction: column;
        box-shadow: $box-shadow;
        border-radius: 8px;
        padding: 24px;
        margin-bottom: 24px;
        width: 100%;

        @include screen-size(desktop) {
            &:last-child {
                margin: 0;
            }

            display: none;
        }

        @media (width <= 992px) {
            margin-bottom: 24px;
        }

        &__top {
            margin-bottom: 7px;
        }

        &-headline {
            @include alter-universal-body-text-accent;

            color: $alter-dark;
            margin-bottom: 8px;
        }

        &-family-or-single-person {
            margin-bottom: 8px;
            display: flex;
        }

        &-date {
            margin-bottom: 8px;
        }

        &-duration {
            margin-bottom: 8px;
        }

        &__price {
            margin-right: 8px;

            &-container {
                display: flex;
                white-space: nowrap;
                padding-top: 16px;
                border-top: 1px solid $alter-gray;
            }
        }

        &-bullet {
            color: $alter-dark-20;
            margin: 0 4px;
        }
    }

    &__tooltip {
        color: $alter-dark-60;
    }

    &__checkbox {
        &::before {
            width: 14px !important;
            height: 14px !important;
            border-radius: 2px !important;
        }

        &::after {
            top: 2px !important;
            left: -26px !important;
            width: 18px !important;
            height: 18px !important;
        }

        &__text {
            margin-left: 3px;
            margin-bottom: 3px;
        }
    }

    &__discount-divider {
        margin: 1rem 0;
    }

    &__title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__card-list {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    & label > .control-label {
        width: 100%;
    }

    &__deposit {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 16px;
    }

    &__depositBannerDesktop {
        margin-top: 24px;
        display: none;
        @media (width >= 992px) {
            display: block;
            width: 320px;
            margin-left: 32px;
        }
    }

    &__depositBannerMobile {
        display: block;
        width: 100%;
        @media (width >= 992px) {
            display: none;
        }
    }
}
