@import "variables";
@import "colors";

html {
    box-sizing: border-box;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
}

input,
select,
button {
    border: none;
    background: transparent;
}

.rating-btn {
    color: #8b8b88;
    background-color: #fff;
    border-style: solid;
    border-color: #d4d4cc;
    border-width: 1px;
    border-radius: 5px;
    padding: 7px 9px;
    font-size: small;
    font-weight: bold;
    margin-left: 3px;
    margin-right: 3px;
}

.rating-btn.active {
    color: #fff;
    background-color: $alter-green-text;
    border-color: $alter-green-text;
}

.overlay-container {
    position: relative;
}

.overlay {
    position: absolute;
    inset: 0;
    background-color: #fff;
    z-index: 1;

    &--hidden {
        display: none;
        z-index: 0;
    }
}

ul.funnel_docs {
    padding-left: 40px;
    margin-bottom: 16px;
    list-style-type: disc;
}

.hide-scrollbar-x {
    overflow-x: scroll;
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

.overflow-gradient {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 1px;
        right: 0;
        width: 63px;
        background: linear-gradient(270deg, #fff 3.75%, rgb(255 255 255 / 0%) 97.5%);
        pointer-events: none;
    }
}
