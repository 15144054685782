@import "../../../styles/tokens";

.toggle {
    cursor: pointer;
    position: relative;
    &__input {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
    }
    &__check {
        width: 40px;
        height: 24px;
        border-radius: 99px;
        background: $alter-dark-20;
        cursor: pointer;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: 2px;
            top: 2px;
            width: 20px;
            height: 20px;
            background: $alter-white;
            border-radius: 50%;
            transition: 0.3s;
        }
    }

    &.is-checked {
        .toggle {
            &__check {
                background: $alter-primary-lite;
                &::before {
                    left: calc(100% - 22px);
                }
            }
        }
    }

    &.is-disabled {
        .toggle {
            &__input {
                cursor: default;
            }
            &__check {
                opacity: 0.3;
            }
        }
    }
    &__label {
        margin-bottom: 0;
    }
}
