@import "../../../../../../styles/tokens";

.next-page-button-mobile-container {
    @media (width <= 991px) {
        height: 70px;
    }
}

.next-page-button-mobile {
    box-shadow: $box-shadow;
    padding: 8px 16px;

    &.bottom-fixed {
        @media (width <= 991px) {
            display: block;
        }
    }
}
