.personal-info {
    color: rgb(50 54 72 / 60%);
    font-size: 16px;
    line-height: 24px;

    &__link {
        text-decoration: underline;
        color: rgb(50 54 72 / 60%);
    }
}
