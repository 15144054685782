@import "../../../../../node_modules/bulma/sass/utilities/mixins";
@import "../../../../styles/tokens";

.go-to-callbutton {
    &__placeholder {
        @media (width <= 1023px) {
            height: 68px;
        }
    }

    &__wrapper {
        padding: 0 8px 8px;
        box-shadow: 0 8px 48px -8px rgba(0 0 0 / 45%);
        background-color: transparent;
        display: block;

        @include desktop {
            display: none;
        }
    }

    &__is-desktop {
        display: none;
        width: 100%;
        margin-top: 16px;

        @include desktop {
            display: flex;
        }
    }

    &__link {
        &:hover {
            text-decoration: none;
        }
    }

    .button.is-primary[disabled] {
        color: $alter-dark-60;
        background: $alter-gray-50;
        border-color: inherit;
    }
}
