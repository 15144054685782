@import "colors";
@import "typography";

$easing: ease-out;
$radius-small: 4px;
$radius: 8px;
$radius-large: 16px;
$radius-rounded: 9999px;
$speed: 86ms;
$box-shadow: 0 8px 48px -8px rgb(0 0 0 / 15%);
$body-overflow-y: initial;
$body-line-height: 1.15;
$label-color: $grey-dark;
$label-weight: $weight-normal;
$input-focus-border-color: $alter-primary-dark;
$input-focus-box-shadow-size: 0 0 0 0;
$input-disabled-color: $alter-dark-20;
$input-arrow: $alter-dark-30;
$input-height: 48px;
$control-height: 48px;
$control-line-height: 1.33;
$control-border-width: 1px;
$control-padding-vertical: calc(12px - #{$control-border-width});
$control-padding-horizontal: calc(16px - #{$control-border-width});
$radio-border: 2px solid $alter-dark-30;
$radio-size: 14px;
$checkbox-size: 14px;
$checkbox-border-radius: 3px;
$checkbox-border-width: 2px;
$checkbox-border-color: $alter-dark-30;
$box-radius: 8px;
$button-padding-horizontal: 16px;
$alter-control-transition:
    background-color 0.3s ease,
    color 0.3s ease,
    border-color 0.3s ease,
    fill 0.3s ease,
    filter 0.3s ease,
    opacity 0.3s ease,
    transform 0.3s ease;
