@use "sass:color";

// Figma library colors
$alter-primary-lite: #29b981;
$alter-primary-dark: #26aa76;
$alter-primary-20: color.change($alter-primary-lite, $alpha: 0.2);
$alter-primary-10: color.change($alter-primary-lite, $alpha: 0.1);
$alter-danger-lite: #ef6a5f;
$alter-danger-dark: #db6258;
$alter-danger-20: color.change($alter-danger-lite, $alpha: 0.2);
$alter-advanced-indigo: #4f5fab;
$alter-advanced-yellow: #f8bb60;
$alter-advanced-yellow-20: color.change($alter-advanced-yellow, $alpha: 0.2);
$alter-dark: #323648;
$alter-dark-60: color.change($alter-dark, $alpha: 0.6);
$alter-dark-30: color.change($alter-dark, $alpha: 0.3);
$alter-dark-20: color.change($alter-dark, $alpha: 0.2);
$alter-gray: #f0eeeb;
$alter-gray-80: #f6f6f5;
$alter-gray-50: color.change($alter-gray, $alpha: 0.5);
$alter-gray-20: color.change($alter-gray, $alpha: 0.2);
$alter-white: #fff;
$alter-white-60: color.change($alter-white, $alpha: 0.6);
$alter-white-30: color.change($alter-white, $alpha: 0.3);

// custom colors for bulma
$black: hsl(0deg 0% 4%);
$black-bis: hsl(0deg 0% 7%);
$black-ter: hsl(0deg 0% 14%);
$grey-darker: hsl(0deg 0% 21%);
$grey-dark: $alter-dark;
$grey: $alter-dark-60;
$grey-light: hsl(0deg 0% 71%);
$grey-lighter: hsl(0deg 0% 86%);
$grey-lightest: hsl(0deg 0% 93%);
$white-ter: hsl(0deg 0% 96%);
$white-bis: hsl(0deg 0% 98%);
$white: $alter-white;
$orange: hsl(14deg 100% 53%);
$yellow: hsl(44deg 100% 77%);
$green: $alter-primary-lite;
$turquoise: hsl(171deg 100% 41%);
$cyan: hsl(207deg 61% 53%);
$blue: hsl(229deg 53% 53%);
$purple: hsl(271deg 100% 71%);
$red: $alter-danger-lite;
$link: $alter-primary-lite;
$primary: $alter-primary-lite;
$info: $alter-advanced-indigo;
$link-hover: $alter-primary-dark;

.has-bg-primary-light {
    background-color: $alter-primary-lite;
}

.has-bg-primary-dark {
    background-color: $alter-primary-dark;
}

.has-bg-primary-20 {
    background-color: $alter-primary-20;
}

.has-bg-primary-10 {
    background-color: $alter-primary-10;
}

.has-bg-danger-lite {
    background-color: $alter-primary-lite;
}

.has-bg-danger-dark {
    background-color: $alter-danger-dark;
}

.has-bg-danger-20 {
    background-color: $alter-danger-20;
}

.has-bg-advanced-indigo {
    background-color: $alter-advanced-indigo;
}

.has-bg-advanced-yellow {
    background-color: $alter-advanced-yellow;
}

.has-bg-advanced-yellow-20 {
    background-color: $alter-advanced-yellow-20;
}

.has-bg-dark {
    background-color: $alter-dark;
}

.has-bg-dark-60 {
    background-color: $alter-dark-60;
}

.has-bg-dark-30 {
    background-color: $alter-dark-30;
}

.has-bg-dark-20 {
    background-color: $alter-dark-20;
}

.has-bg-gray {
    background-color: $alter-gray;
}

.has-bg-gray-80 {
    background-color: $alter-gray-80;
}

.has-bg-gray-50 {
    background-color: $alter-gray-50;
}

.has-bg-gray-20 {
    background-color: $alter-gray-20;
}

.has-bg-white {
    background-color: $alter-white;
}

.has-bg-white-60 {
    background-color: $alter-white-60;
}

.has-text-white-60 {
    color: $alter-white-60;
}

.has-text-dark-60 {
    color: $alter-dark-60;
}

.has-text-dark-30 {
    color: $alter-dark-30;
}

.has-text-danger-lite {
    color: $alter-danger-lite;
}

.has-text-alter-light {
    color: $alter-primary-lite;
}

:root {
    &.alter-theme-light {
        --alter-primary-bg-color: #{$alter-white};
        --alter-secondary-bg-color: #{$alter-white};
        --alter-headline-primary-text-color: #{$alter-dark};
        --alter-headline-text-color: #{$alter-dark-30};
        --alter-primary-text-color: #{$alter-dark};
        --alter-secondary-text-color: #{$alter-dark-60};
        --alter-secondary-hover-color: #{$alter-dark};
        --alter-primary-icon-color: #{$alter-dark-30};
        --alter-separator-color: #{$alter-gray};
        --alter-primary-color-header-nav-link: #{$alter-dark};
        --alter-secondary-color-header-nav-link: #{$alter-primary-lite};
        --alter-control-primary-content-color: #{$alter-primary-lite};
        --alter-control-primary-content-color-hover: #{$alter-primary-dark};
        --alter-control-default-bg-color: #{$alter-white};
        --alter-control-hover-default-bg-color: #{$alter-gray-80};
        --alter-control-default-content-color: #{$grey-darker};
        --alter-control-hover-default-content-color: #{$alter-dark};
        --alter-control-default-border-color: #{$alter-dark-30};
        --alter-control-default-border-hover-color: #{$alter-dark-60};
        --alter-user-avatar-border-color: #{$alter-primary-20};
        --alter-primary-link-color: #{$alter-primary-lite};
        --alter-primary-link-color-hover: #{$alter-primary-dark};
    }

    &.alter-theme-dark {
        --alter-primary-bg-color: #{$alter-dark};
        --alter-secondary-bg-color: hsl(229deg 14% 28%);
        --alter-headline-primary-text-color: #{$alter-white};
        --alter-headline-text-color: #{$alter-white-30};
        --alter-primary-text-color: #{$alter-white};
        --alter-secondary-text-color: #{$alter-white-60};
        --alter-secondary-hover-color: #{$alter-white};
        --alter-primary-icon-color: #{$alter-white-30};
        --alter-separator-color: #{color.change($alter-white, $alpha: 0.1)};
        --alter-primary-color-header-nav-link: #{$alter-white-60};
        --alter-secondary-color-header-nav-link: #{$alter-white};
        --alter-control-primary-content-color: #{$alter-primary-dark};
        --alter-control-primary-content-color-hover: #{$alter-primary-lite};
        --alter-control-default-bg-color: transparent;
        --alter-control-hover-default-bg-color: transparent;
        --alter-control-hover-default-content-color: #{$alter-white};
        --alter-control-default-border-color: rgb(132 134 145);
        --alter-control-default-border-hover-color: rgb(132 134 145);
        --alter-user-avatar-border-color: #{$alter-primary-dark};
        --alter-primary-link-color: #{$alter-white};
        --alter-primary-link-color-hover: #{$alter-white-60};
    }
}
