@import "../../styles-branded/colors";
@import "../../styles-branded/typography";
@import "../../../../node_modules/bulma/sass/utilities/mixins";

@mixin blm-h1-mobile {
    font-size: $size-3;
    line-height: 1.33;
}

@mixin blm-h2-mobile {
    @include blm-h1-mobile;

    font-weight: $weight-medium;
}

@mixin headline1 {
    font-size: $size-1;
    line-height: 1.23;
    font-weight: $weight-semibold;
}

@mixin headline2 {
    font-size: $size-2;
    line-height: 1.33;
    font-weight: $weight-medium;
}

@mixin headline3 {
    font-size: $size-3;
    line-height: 1.33;
}

@mixin headline4 {
    font-size: $size-4;
    line-height: 1.2;
    font-weight: $weight-medium;
}

@mixin headline5 {
    font-size: $size-5;
    line-height: 1.2;
    font-weight: $weight-normal;
}

@mixin headline5-medium {
    font-size: $size-5;
    line-height: 1.2;
    font-weight: $weight-medium-bold;
}

@mixin headline3-medium {
    font-size: $size-3;
    line-height: 1.33;
    font-weight: 400;
}

@mixin headline3-bold {
    font-size: $size-3;
    line-height: 1.33;
    font-weight: 450;
}

.blm-h1 {
    @include blm-h1-mobile;

    @include tablet {
        font-size: $size-1;
        line-height: 1.23;
    }

    font-weight: $weight-semibold;
}

.blm-h2 {
    @include blm-h2-mobile;

    @include tablet {
        font-size: $size-2;
        line-height: 1.33;
        font-weight: $weight-medium;
    }
}

.blm-h3 {
    @include blm-h1-mobile;
}

.blm-h4 {
    font-size: $size-4;
    line-height: 1.2;
    font-weight: $weight-medium;
}
