@import "../../../styles/colors";
@import "../../../styles/tokens";
@import "../../../../node_modules/bulma/sass/utilities/mixins";
@import "../../../ui-kit/styles-branded/misc";

.session-date-page-container {
    @media (width <= 991px) {
        height: 98px;
    }
}

.session-date {
    display: flex;
    flex-direction: column;
    box-shadow: $box-shadow;
    border-radius: 8px;
    padding: 24px;

    &:last-child {
        margin: 0;
    }

    @include screen-size(desktop) {
        width: 672px;
        padding: 24px 32px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 16px;

        @media (width <= 992px) {
            gap: 24px;
        }
        @include screen-size(desktop) {
            flex-direction: row;
            margin-top: 0;
        }
    }

    &__one-option {
        color: rgb(50 54 72 / 60%);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    &__price-and-time {
        color: rgb(50 54 72 / 60%);
        font-weight: 400;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        flex-flow: column wrap;
        line-height: 24px;
        @include screen-size(desktop) {
            width: 100%;
            flex-direction: row;
        }

        &__discount {
            margin-left: 6px;
            color: rgb(50 54 72 / 60%);
            position: relative;
            font-weight: 400;
            margin-right: 4px;

            &__container {
                display: flex;
                align-items: center;
                white-space: nowrap;
            }
        }
    }

    &__session-info {
        flex-direction: column;
        box-shadow: $box-shadow;
        border-radius: 8px;
        padding: 24px;
        display: none;
        width: 100%;
        @include screen-size(desktop) {
            display: block;
        }

        &.is-psy-account {
            display: block;
        }

        &__top {
            margin-bottom: 14px;
        }

        &-headline {
            font-weight: 500;
            font-size: 18px;
            color: $alter-dark;
            margin-bottom: 8px;
            line-height: 24px;
        }

        &-family-or-single-person {
            font-weight: 400;
            font-size: 18px;
            margin-bottom: 8px;
            line-height: 24px;
        }

        &-date {
            font-weight: 400;
            font-size: 18px;
            margin-bottom: 8px;
            line-height: 24px;
        }

        &-duration {
            font-weight: 400;
            font-size: 18px;
            margin-bottom: 8px;
            line-height: 24px;
        }

        &-location {
            display: flex;
            max-width: 260px;
            font-weight: 400;
            font-size: 18px;
            margin-bottom: 8px;
            line-height: 24px;

            &__icon {
                margin-right: 2px;
            }
        }

        &-price {
            &-container {
                display: flex;
                align-items: center;
                white-space: nowrap;
                border-top: 1px solid $alter-gray;
            }
        }

        &-bullet {
            color: $alter-dark-20;
            margin: 0 4px;
            display: none;
            @include screen-size(desktop) {
                display: inline;
            }
        }

        &-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 24px;

            @include screen-size(desktop) {
                width: 320px;
                margin-left: 32px;
            }
        }

        &__skeleton {
            min-height: 320px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-psy-account {
            &-tooltip {
                .rc-tooltip-inner {
                    max-width: 360px;
                }

                &__date {
                    &::first-letter {
                        text-transform: uppercase;
                    }
                }
            }

            &-time-zone {
                font-size: 16px;
                line-height: 24px;
                color: $alter-dark-60;
            }

            &-link {
                path {
                    transition: $alter-control-transition;
                    fill: $alter-dark-30;
                }

                a {
                    color: $alter-dark-60;
                    text-decoration: none;

                    &:hover {
                        color: var(--alter-secondary-hover-color);

                        path {
                            fill: var(--alter-secondary-hover-color);
                        }
                    }
                }

                &-text {
                    position: relative;
                    display: inline-block;
                    margin-right: 4px;

                    &::before {
                        content: "";
                        display: block;
                        border-bottom: 1px solid $alter-dark-60;
                        height: 1px;
                        position: absolute;
                        bottom: 2px;
                        left: 0;
                        width: 100%;
                    }
                }
            }
        }
    }

    &__next-page-button-additional-info {
        font-size: 16px;
        line-height: 24px;
        color: rgb(50 54 72 / 60%);
        font-weight: 400;
        margin-bottom: 5px;
    }
}

.no-wrap {
    white-space: nowrap;
}
