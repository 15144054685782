// https://stackoverflow.com/a/22603610/445715
.strike {
    display: block;
    text-align: right;
    overflow: hidden;
    white-space: nowrap;
}

.strike > .strike-content {
    position: relative;
    display: inline-block;
}

.strike > .strike-content::before,
.strike > .strike-content::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: lightgrey;
}

.strike > .strike-content::before {
    right: 100%;
    margin-right: 5px;
}

.strike > .strike-content::after {
    left: 100%;
    margin-left: 5px;
}

.strike > .strike-content-right {
    margin-right: 30px;
}
