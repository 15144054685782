@import "../../../node_modules/bulma/sass/utilities/mixins";

.stripe {
    font-family: FuturaPT, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100vw;

    @media only screen and (width <= 600px) {
        form {
            width: 80vw;
            min-width: initial;
        }
    }
}

.stripe-form {
    width: 30vw;
    min-width: 350px;

    @include tablet {
        min-width: 500px;
    }

    align-self: center;
    box-shadow:
        0 0 0 0.5px rgb(50 50 93 / 10%),
        0 2px 5px 0 rgb(50 50 93 / 10%),
        0 1px 1.5px 0 rgb(0 0 0 / 7%);
    border-radius: 7px;
    padding: 40px;
}

.stripe-form-error {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow:
        0 0 0 0.5px rgb(50 50 93 / 10%),
        0 2px 5px 0 rgb(50 50 93 / 10%),
        0 1px 1.5px 0 rgb(0 0 0 / 7%);
    border-radius: 7px;
    padding: 40px;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-top: 30px;
}

.stripe-form__message {
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
}

.stripe-form__payment-element {
    margin-bottom: 24px;
}

.stripe-form__submit-button {
    width: 100%;
}

.stripe-form__spinner,
.stripe-form__spinner::before,
.stripe-form__spinner::after {
    border-radius: 50%;
}

.stripe-form__spinner {
    color: #fff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0 auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    transform: translateZ(0);
}

.stripe-form__spinner::before,
.stripe-form__spinner::after {
    position: absolute;
    content: "";
}

.stripe-form__spinner::before {
    width: 10.4px;
    height: 20.4px;
    background: #29b981;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    transform-origin: 10.4px 10.2px;
    animation: loading 2s infinite ease 1.5s;
}

.stripe-form__spinner::after {
    width: 10.4px;
    height: 10.2px;
    background: #29b981;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    transform-origin: 0 10.2px;
    animation: loading 2s infinite ease;
}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

a.disabled {
    pointer-events: none;
}
