@import "../../../../../../../styles/tokens";

.form-booking-input-container {
    margin-bottom: 16px;

    &__bottom-gap {
        margin-bottom: 8px;
    }

    &__error-text {
        margin-top: 10px;
        color: $alter-danger-lite;
    }
}
