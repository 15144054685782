@import "variables";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "tokens";
@import "../../node_modules/bulma/sass/utilities/mixins";

.profile-layout-item {
    max-width: 1024px;
    padding-left: 16px;
    padding-right: 16px;

    @include tablet {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media (width >= 1051px) {
        padding-right: #{"min(336px, 33.6%)"};
    }

    @media (width >= 992px) and (width <= 1050px) {
        padding-right: #{"min(360px, 36%)"};
    }

    .padding-less & {
        padding-left: 0;
        padding-right: 0;
    }
}

.alter-header {
    position: relative;
    width: 100%;
    min-height: 50px;
    padding: 0 16px;

    @include tablet {
        padding: 0 32px;
    }
}

.alter-header__back-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: spacer(3);
    display: flex;
    align-items: center;
}

.page-common {
    background-color: $alter-gray-20;

    &--alter-lite {
        background-color: #fbfbfb;
    }
}
