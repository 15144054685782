@import "../../../../../styles/tokens";

.booking-form-time-request-info {
    &__emoji {
        width: 18px;
        height: 16px;
        position: relative;
        bottom: -4px;
        margin-right: 7px;
        margin-left: 4px;
    }

    &__title {
        margin-right: 4px;
    }

    &__tooltip {
        .rc-tooltip-inner {
            max-width: 360px;
        }

        &-content {
            color: $alter-dark-60;
            line-height: 24px;
        }
    }
}
