@import "../../../../styles/colors";
@import "../../../../styles/tokens";

.booking-online-off-offline-tabs {
    font-size: 18px;
    line-height: 24px;
    color: $alter-dark-60;
    font-weight: 500;
    margin-bottom: 8px;

    &__list {
        background-color: $alter-gray-80;
        display: flex;
        border-radius: 8px;
    }

    &__item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;

        &.is-selected {
            background-color: $alter-primary-20;
            color: $alter-primary-lite;
            cursor: default;
            border-radius: 8px;
        }
    }
}
