@import "../../../../styles/tokens";

.card-item-radio {
    padding: 12px 16px 12px 13px !important;

    & .control-label {
        padding-left: 23px !important;
    }
}

.card-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;

    &__last-card-digits {
        @include alter-universal-body-text;

        flex: 1;
        margin-left: 9px;
    }
}
