@import "../../../../styles/tokens";

.guaranties {
    padding: 16px;
    background: rgb(41 185 129 / 10%);
    border-radius: 8px;
    height: max-content;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 16px;
    justify-content: space-between;
    line-height: 24px;
    @include screen-size(desktop) {
        font-size: 18px;
    }

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__text {
        margin-left: 4px;
        @include screen-size(desktop) {
            margin-right: 30px;
        }
    }

    &__tooltip {
        min-width: 700px;

        &__headline {
            color: $alter-dark;
        }

        &__list {
            margin-top: 2px;
            margin-left: 4px;

            &__headline {
                color: $alter-dark;
            }

            &-item {
                color: rgb(50 54 72 / 60%);
                display: flex;
                line-height: 24px;

                &::before {
                    content: unicode("2022");
                    display: block;
                    margin-right: 4px;
                    color: rgb(50 54 72 / 60%);
                }
            }
        }
    }
}

.booking-details {
    padding: 16px;
    border-radius: 8px;
    background: $alter-advanced-yellow-20;
}

.booking-details--is-default {
    display: flex;

    .booking-details__emoji {
        margin-right: 4px;
        margin-top: -8px;
        height: 18px;
        width: 18px;
    }
}
