$alter-green-focus: #29b981;
$alter-green-text: #2bc88b;
$alter-green-active: #26a976;
$alter-green-marker: #00cb7c;
$alter-dark-green-focus: #2ba86b;
$alter-lightgrey-background: #f5f5f2;
$alter-lightgrey-background-alpha: rgba(#f5f5f2, 93%);
$alter-online-booking-orange: #ff7460;
$alter-online-booking-orange-background: #ff613b;
$alter-discount-badge-background: #ff7460;
$alter-discount-price-color: #ff2000;

// переменные ниже нужны, чтобы переопределить цвета контролов в bootstrap
$available-background: #d3e0f1;
$active-background: #d3e0f1;
$disabled-color: #4d4d4d;
$disabled-background: #ddd;
$primary: $alter-green-text;
$palette: (
    "black": (
        0: #000,
        1: rgb(0 0 0 / 90%),
        2: rgb(0 0 0 / 80%),
        4: rgb(0 0 0 / 70%),
        6: rgb(0 0 0 / 60%),
    ),
    "green": (
        0: #00cb7c,
        1: #2bc88b,
        2: #29b981,
        3: #26aa76,
        4: #2ba86b,
    ),
    "red": (
        0: #ff7460,
        1: #ef6a5f,
        2: #db6258,
        3: #ff613b,
    ),
    "yellow": (
        0: #ffc107,
    ),
    "gray": (
        0: #f6f6f5,
        1: #aba9a5,
        2: #f0eeeb,
    ),
    "blue": (
        0: rgba(#323648, 0.1),
        1: rgba(#323648, 0.2),
        2: rgba(#323648, 0.3),
        3: rgba(#323648, 0.6),
        4: #323648,
    ),
    "indigo": (
        0: #a2a7d3,
    ),
    "white": white,
    "transparent": transparent,
);
$border: (
    "b": "border",
    "bt": "border-top",
    "bb": "border-bottom",
    "bl": "border-left",
    "br": "border-right",
);
$spacer: 8px;
$spacers: (
    0: 0,
    1: (
        $spacer * 0.5,
    ),
    2: $spacer,
    3: (
        $spacer * 2,
    ),
    4: (
        $spacer * 3,
    ),
    5: (
        $spacer * 4,
    ),
    6: (
        $spacer * 5,
    ),
    7: (
        $spacer * 6,
    ),
    8: (
        $spacer * 7,
    ),
);
$main-font-size: 16px;
$font-sizes: (
    0: 11px,
    1: 12px,
    2: 14px,
    3: $main-font-size,
    4: 18px,
    5: 20px,
    6: 24px,
    7: 30px,
    8: 52px,
);
$font-weights: (
    0: 400,
    1: 500,
);
$line-heights: (
    0: 1,
    1: 1.25,
    2: 1.4,
    3: 1.5,
);
$transform: uppercase, lowercase, none;
$decoration: none, line-through, underline;
$text-align: left, right, center;
$border-radiuses: (
    0: 0,
    1: 1px,
    2: 3px,
    3: 4px,
    4: 5px,
    5: 8px,
    6: 20px,
    7: 25px,
);
$columns-count: 2, 3, 4;
$flex-basis: (
    "25": 25%,
    "33": 33%,
    "50": 50%,
    "100": 100%,
);
