@import "../../../styles/tokens";
@import "../../../../node_modules/bulma/sass/utilities/mixins";

.try-again {
    max-width: 344px;
    display: flex;
    flex-direction: column;
    box-shadow: $box-shadow;
    border-radius: 8px;
    padding: 24px;
    color: rgb(50 54 72 / 100%);
    margin: 16px 16px 20px;
    @include tablet {
        margin-top: 0;
        max-width: 672px;
        padding: 24px 32px;
    }

    &__container {
        margin-left: 16px;
        margin-right: 16px;
        display: flex;
        justify-content: center;
    }

    &__card-illustration {
        display: none;
        @include tablet {
            display: block;
            width: 608px;
            height: 344px;
        }
    }

    &__headline {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        @include tablet {
            font-size: 24px;
            line-height: 32px;
        }
    }

    &__text {
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 17px;
    }

    &__primary-button {
        color: white;
        background-color: $alter-primary-lite;
        border-radius: 8px;
        text-align: center;
        padding: 16px 32px;
        font-weight: 500;
        width: 100%;
        font-size: 18px;
        line-height: 18px;
        margin-top: 15px;

        &:focus {
            :not(:focus-visible) {
                outline: none;
            }
        }

        &:hover {
            color: white;
            background-color: $alter-primary-dark;
        }
    }

    &__secondary-button {
        line-height: 14px;
        margin-top: 15px;
    }
}
