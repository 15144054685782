@import "normalize";
@import "variables";
@import "common";
@import "colors";
@import "fonts";
@import "icons";
@import "buttons";
@import "tooltip";
@import "layout";
@import "toastify";
@import "bootstrap";

:root {
    --window-height: 100vh;
}

:root {
    --alter-audio-slider-position: 0%;
}

html {
    background-color: var(--alter-primary-bg-color) !important;
    margin-right: 0 !important;
}

body {
    margin: 0;
    padding: 0;
    min-height: var(--window-height);
    display: flex;
    flex-direction: column;
    line-height: 1.15;
    background-color: var(--alter-primary-bg-color);
}

h2,
h3 {
    font-weight: bold;
}

.psychologists .card-footer {
    display: flex;
    justify-content: space-around;
}

.survey-questions {
    h5,
    h4,
    h3,
    h2 {
        font-weight: 500;
        margin-bottom: 4px;
    }

    h4 {
        font-size: 1.5rem;
    }

    h3,
    h2 {
        font-weight: 700;
        font-size: 1.75rem;
    }

    h2 {
        font-size: 2rem;
    }

    .radio,
    .checkbox {
        line-height: normal;
        cursor: default;
    }

    .radio + .radio {
        margin-left: initial;
    }

    .card-footer {
        padding: 0.75rem 1.25rem;
        background-color: rgb(0 0 0 / 3%);
        border-top: 1px solid rgb(0 0 0 / 12.5%);

        &:last-child {
            border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
        }
    }
}

.survey-questions {
    width: 100%;
    max-width: 700px;
    font:
        18px FuturaPT,
        sans-serif;
    font-weight: 400;
    margin-bottom: auto;
}

.main {
    margin: 0 auto;
}

/* stylelint-disable-next-line selector-class-pattern */
.sv_container h5 {
    font-size: 1.2rem;
    text-align: left;
}

@media (width >= 480px) {
    .psychologists .card-footer {
        justify-content: flex-start;
    }

    .card-footer .btn {
        margin-left: 0.5em;
    }
}

.discount-badge {
    position: absolute;
    right: -1em;
    top: 0;
    background-color: $alter-discount-badge-background;
    color: white;
    @extend %font-size-3;
    @extend %font-futura-600;

    padding: 0.5em 1.2em;
    border-radius: 2em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.non-discount-price {
    text-decoration: line-through;
    opacity: 0.6;
}

.discount-price {
    color: $alter-online-booking-orange;
}

.spinner {
    margin: 0 auto;
    display: block;
}

.avatar-photo {
    border-radius: 50%;
}

.small-photo {
    width: 50px;
    height: 50px;
    margin: 0 0.5em;
}

.booking-ui {
    display: flex;
    flex-direction: column;
    align-items: center;

    .error-message,
    .status-message,
    .info-message {
        text-align: center;
        padding: 0.5em 0.7em;
    }

    .status-message {
        font-size: 1.5em;
        font-weight: 600;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.btn-group > * {
    margin: 0.5em;
}

.modal-backdrop {
    height: 100% !important;
}

.modal-open {
    .modal {
        z-index: 1050;
    }

    .modal-body {
        font-size: 18px;
    }

    .modal-content {
        max-width: 800px;
        margin: 0;

        p + p {
            margin-top: 14px;
        }
    }
}

.radio label input {
    margin-top: -4px !important;
    margin-right: 2px !important;
}

.word-break-normal {
    word-break: normal;
}

.error {
    margin-top: 20px;
}

.error h4 {
    color: darkred;
}

.profile-items {
    font-size: 16px;
    text-align: left;
}

.profile-items strong {
    font-weight: bold;
}

.profile-items .about {
    padding-bottom: 1em;
}

.page-item .page-link {
    transition: none;
}

.scrollbox {
    height: 300px;
    overflow-y: scroll;
}

.sv-required-question {
    color: red;
    font-size: small;
}

.alert .spinner {
    max-height: 2em;
}

.alert .message {
    width: 100%;
}

@media (width <= 480px) {
    .alert .spinner {
        max-height: 5em;
    }
}

.link-button {
    color: #fff !important;
}

/* stylelint-disable-next-line selector-class-pattern */
.b-container {
    @include make-container;
    padding-right: 0;
    padding-left: 0;
}

@media (width >= 576px) {
    /* stylelint-disable-next-line selector-class-pattern */
    .b-container {
        max-width: none;
    }
}

@media (width >= 768px) {
    /* stylelint-disable-next-line selector-class-pattern */
    .b-container {
        max-width: none;
    }
}

.alert {
    &-info {
        background-color: $alter-green-focus;
        color: $alter-lightgrey-background;
    }
}

.general-button {
    @include psyalter-button;

    &-container {
        display: flex;
        justify-content: center;
        width: auto;
    }
}

.button-search {
    @include psyalter-button;

    width: initial;
    max-width: initial;
    min-width: 210px;
    padding: 0 1em;

    &:disabled {
        cursor: default;
    }

    .spinner {
        max-height: 2em;
    }
}

.psychologist {
    &__buttons-wrapper {
        width: 100%;
        padding: 24px;
        margin-top: auto;
        gap: 10px;
    }

    &__button {
        @include psyalter-button;

        font-weight: inherit;

        &-email,
        &-catalog {
            @include psyalter-button;

            &.small {
                min-height: unset;
                width: auto;
                padding: 5px 15px;
            }
        }

        &-booking {
            @include psyalter-button;

            color: $alter-online-booking-orange;
            border: 1px solid $alter-online-booking-orange;

            &:hover,
            &:focus {
                color: #fff;
                background-color: $alter-online-booking-orange-background;
            }

            &-highlighted {
                color: #fff;
                background-color: $alter-online-booking-orange;
                border: 1px solid $alter-online-booking-orange;

                &:hover,
                &:focus {
                    background-color: $alter-online-booking-orange-background;
                }
            }
        }
    }
}

.pay-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mb-auto {
    margin-bottom: auto;
}

.mt-auto {
    margin-top: auto;
}

.ml-auto {
    margin-left: auto;
}

.custom-control {
    display: flex;

    &-label {
        display: flex;
        align-items: center;
    }
}

.collapsible-information {
    &-button {
        color: $alter-green-active;
        align-self: center;
        padding-left: 0.5em;
    }

    &-text {
        &-with-background {
            background-color: $alter-lightgrey-background;
        }

        border: gray 1px solid;
        border-radius: 0.5em;
        box-shadow: 0 0 8px -2px $alter-green-active;
        padding: 0.5em;
        max-width: 360px;
        margin-left: auto;

        &::first-letter {
            text-transform: uppercase;
        }
    }
}

.back-button {
    @include link-like;
}

.telegramButton {
    margin-top: 16px;
    @include mobile {
        margin-top: 0;
    }
}

.transition-ease {
    transition:
        background-color 300ms ease,
        color 300ms ease,
        border-color 300ms ease;
}

.banner-mobile {
    display: grid;
    row-gap: 24px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.copy-link-notification {
    &__emoji {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-out;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-out;
}
