@import "colors";
@import "variables";
@import "fonts";
@import "common";
@import "tokens";

.data-table-container {
    overflow: auto;
    overflow-y: hidden;
    max-width: 95vw;
}

.data-table {
    @extend %font-futura-400;

    font-size: 1rem;
    line-height: 1.4em;

    @media (width <= 480px) {
        font-size: 0.7rem;
        line-height: 1.6em;
    }

    min-width: 980px;

    .max-width {
        display: inline-block;
        word-break: break-word;
        min-width: 160px;
    }

    .is-highlighted {
        background-color: $alter-advanced-yellow-20;
    }
}

.psy-account-page {
    @extend %font-futura-600;

    font-size: 16px;

    .header-container {
        @include header-container;
    }

    .header {
        @include header;
    }

    .payments-table {
        &__header-cell-client_email,
        &__header-cell-service_name,
        &__cell-client_email,
        &__cell-service_name,
        thead tr:first-child th:not(:last-child) {
            border-right: solid 2px $alter-dark !important;
        }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .b-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 90%;
        margin: 0 1em;
        flex-grow: 2;

        @media (width >= 640px) {
            max-width: 640px;
        }
        @include screen-size(desktop) {
            max-width: 980px;
        }
    }

    .spacer {
        flex-grow: 2;
    }

    .nav {
        font-size: 0.7rem;
    }

    .nav-link {
        cursor: pointer;
        color: initial;
    }

    .go-back-link {
        @include link-like;

        align-self: flex-start;
    }

    h3.client-name {
        width: 100%;
    }

    h5.client-email {
        font-size: 16px;
    }

    h3.client-working-status {
        color: $alter-danger-dark;
    }

    h5 {
        font-weight: 500;
    }

    .user-email {
        margin-bottom: 1.5em;
    }

    .error-message {
        text-align: center;
        padding: 0.5em 0.7em;
        margin-bottom: 1.5em;
    }

    .dismissible-error-message,
    .dismissible-result-message {
        text-align: center;
        padding: 0.5em 2.7em 0.5em 0.7em;
        margin-bottom: 1.5em;

        .close {
            padding: 0 0.3em 0 0;
        }
    }

    .calendar-list {
        display: flex;
        flex-direction: column;
        font:
            18px FuturaPT,
            sans-serif;
        font-weight: 400;
        margin: 1.5rem 1.5rem 0;

        &__header,
        &__button {
            font-size: 20px;
            font-weight: 700;
        }
    }

    .account-fields {
        display: grid;
        grid: 1fr / 1fr 1fr;
        font-size: 0.8rem;

        @media (width <= 480px) {
            font-size: 0.7em;
        }

        > :first-child {
            grid-column: 1 / 3;
            text-align: center;
            font-size: 1.1em;
        }

        > :nth-child(2) {
            grid-column: 1 / 3;
            text-align: center;
            font-size: 1.1em;
            padding-bottom: 1.2em;
        }

        > :nth-child(2n + 3) {
            text-align: right;
            padding-right: 0.5em;
        }
    }

    hr {
        width: 100%;
    }

    .payment-tool-desc {
        margin-top: 1em;
    }

    .callendar-actions {
        flex-direction: column;
        gap: 16px;
        @include tablet {
            flex-direction: row;
        }
    }

    .callendar-actions-link-1,
    .callendar-actions-link-2 {
        @include tablet {
            width: 200px;
        }
    }

    .callendar-actions-link-2 {
        @include tablet {
            justify-content: flex-end;
        }
    }

    .google-calendar-link {
        display: flex;
        align-items: center;
    }

    .google-calendar-icon {
        width: 1.5em;
        height: 1.5em;
        padding: 0 0.2em;
    }

    .fc-list-heading-main::after {
        content: " ";
    }

    .fc-toolbar h2 {
        font-size: 18px;
        font-weight: bold;
    }

    @include questions;

    .questions {
        max-width: 980px;
    }

    .account-settings-block {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        font-size: 0.8rem;
        padding-bottom: 0.5em;

        label:not(.blm-radio) {
            margin: 5px;
        }

        input {
            border: 0.1em solid $alter-green-text;

            &:invalid {
                border: red solid 0.1em;
            }
        }

        button {
            margin-top: 10px;
        }

        .alert {
            margin-top: 10px;
        }
    }

    .clients-bookings-block {
        text-align: center;

        @media (width >= 481px) {
            & > :first-child {
                flex-grow: 1;
            }

            & > :last-child {
                flex-grow: 10;
            }
        }

        @media (width <= 480px) {
            flex-direction: column;
        }
    }

    .clients-block {
        text-align: center;
        width: 100%;

        &__table {
            max-width: 95vw;
            overflow: auto;

            .max-width {
                display: inline-block;
                word-break: break-word;
                min-width: 160px;
            }
        }

        @media (width >= 480px) {
            .column {
                text-align: left;
            }
        }

        @media (width >= 481px) {
            & > :first-child {
                flex-grow: 1;
            }

            & > :last-child {
                flex-grow: 10;
            }
        }

        @media (width <= 480px) {
            flex-direction: column;
        }

        .columns {
            margin-top: 8px;
        }
    }

    .fixed-session-time-list {
        .dismissible-error-message,
        .dismissible-result-message {
            margin: 0.5em 0 0.2em;
        }
    }

    .fixed-session-time-entry {
        width: 100%;
        text-align: center;
        padding: 0.5em 0.7em;
        margin: 0.2em;

        .close {
            padding: 0 0.3em 0 0;
        }
    }

    .alert-disabled {
        background-color: $disabled-background;
    }

    .nav-tabs,
    .clients-bookings-block {
        width: 100%;
        max-width: 980px;
    }

    .nav-tabs {
        border-bottom: 1px solid $alter-green-text;

        .nav-link {
            border-color: #cdcdca #cdcdca $alter-green-text;
            @media (width <= 480px) {
                padding: 0.5rem 0;
            }
        }

        .nav-link.active,
        .nav-item.show .nav-link {
            color: $alter-green-text;
            border-color: $alter-green-text $alter-green-text #fff;
            cursor: default;
        }
    }

    .answers__list,
    .booking__list {
        @extend %font-futura-400;

        font-size: 1rem;
        line-height: 1.4em;

        @media (width <= 480px) {
            font-size: 0.7rem;
            line-height: 1.6em;
        }
    }

    .answers__question {
        white-space: pre-line;
    }

    .booking__list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: 1fr;
        grid-row-gap: 0;
        padding: 0.5em 0;

        @media (width >= 481px) {
            align-self: flex-start;
            line-height: 46px;
        }

        &_columns-6 {
            grid-template-columns: repeat(6, 1fr);

            > :nth-child(6n-5),
            > :nth-child(6n-4),
            > :nth-child(6n-3),
            > :nth-child(6n-2),
            > :nth-child(6n-1) {
                border-right: lightgrey solid 1px;
            }
        }

        &_columns-5 {
            grid-template-columns: repeat(5, 1fr);

            > :nth-child(5n-4),
            > :nth-child(5n-3),
            > :nth-child(5n-2),
            > :nth-child(5n-1) {
                border-right: lightgrey solid 1px;
            }
        }

        &_columns-4 {
            grid-template-columns: repeat(4, 1fr);

            > :nth-child(3n-3),
            > :nth-child(3n-2),
            > :nth-child(3n-1) {
                border-right: lightgrey solid 1px;
            }
        }

        &_columns-2 {
            grid-template-columns: repeat(2, 1fr);

            > :nth-child(2n-1) {
                border-right: lightgrey solid 1px;
            }
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 0 0.4em;
        }
    }
}

.ask-user-dialog {
    ul {
        margin: 0.6em 0;
    }

    li::before {
        content: unicode("2022");
        margin-right: 0.5em;
    }
}

.add-client {
    &__header {
        margin-bottom: 1em;
    }

    &__container {
        min-width: min(550px, 80vw);
    }

    &__form {
        grid-auto-rows: 1fr;
        row-gap: 0;
    }
}

.client-settings {
    &__input {
        margin-right: 8px;
    }
}

.score-chart {
    margin: 24px 0;
    width: 100%;
}

.clients-feedback-row {
    cursor: pointer;

    &:hover {
        background-color: $alter-primary-20;
    }

    &_disabled {
        pointer-events: none;
    }
}

.upcoming-booking-widget {
    &__icon {
        width: 16px;
    }

    &__wrapper {
        width: 100%;
    }

    &__button {
        padding-left: 64px;
        padding-right: 64px;
    }
}

.calendar-status {
    padding: 16px;
    background: $alter-danger-20;
    border-radius: 8px;
    height: max-content;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 16px;
    justify-content: space-between;
    line-height: 24px;
    margin-top: 24px;
    @include screen-size(desktop) {
        font-size: 18px;
    }

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__text {
        margin-left: 4px;
        @include screen-size(desktop) {
            margin-right: 30px;
        }
    }

    &__emoji {
        height: 18px;
        width: 18px;
        margin-right: 4px;
        vertical-align: -3px;
    }
}
