@import "../../../node_modules/bulma/sass/utilities/mixins";

.collapse-icon {
    &__mobile {
        display: initial;
    }

    &__desktop {
        display: none;
    }

    @include tablet {
        &__desktop {
            display: initial;
        }

        &__mobile {
            display: none;
        }
    }
}
