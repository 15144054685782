@import "../../styles/tokens";

.spinner-redesign {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;

    &__text {
        color: rgb(50 54 72 / 30%);
        font-size: 18px;
        font-weight: 400;
        margin-top: 2px;
    }

    @keyframes ring {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    &__ring {
        display: inline-block;
        position: relative;
        width: 49px;
        height: 49px;

        div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 48px;
            height: 48px;
            border: 4px solid var(--alter-secondary-color-header-nav-link);
            border-radius: 50%;
            animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: var(--alter-secondary-color-header-nav-link) transparent transparent
                transparent;

            &:nth-child(1) {
                animation-delay: -0.45s;
            }

            &:nth-child(2) {
                animation-delay: -0.3s;
            }

            &:nth-child(3) {
                animation-delay: -0.15s;
            }
        }
    }

    &.is-small &__ring {
        width: 24px;
        height: 24px;
        left: 2px;
        top: 2px;

        div {
            width: 20px;
            height: 20px;
            margin: 0;
            border-width: 2px;
        }
    }

    &.fullscreen {
        height: 100%;
        width: 100%;
    }
}
