@import "../../../../../../node_modules/bulma/sass/utilities/mixins";

.request-time {
    &__emoji {
        width: 18px;
        margin-right: 4px;
    }

    &__text {
        margin-right: 16px;
        @include mobile {
            margin-bottom: 16px;
            margin-right: 0;
        }
    }
    @include mobile {
        flex-direction: column;
    }
}
