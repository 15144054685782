@import "../../../styles/common";

.booking-details-page {
    min-height: 100vh !important;

    @extend %font-futura-600;

    font-size: 16px;

    .header-container {
        @include header-container;
    }

    .header {
        @include header;

        &__psychologist {
            display: flex;
            flex-direction: column;
            align-items: center;

            &-avatar-container {
                position: relative;
                overflow: hidden;
            }

            &-avatar {
                width: 210px;
                height: 210px;
                min-width: 210px;
                min-height: 210px;
                margin-bottom: 15px;
                overflow: hidden;
                border-radius: 50%;
            }

            &-bio {
                text-align: center;
                margin-bottom: 15px;

                @media (width >= 768px) {
                    margin-bottom: 20px;
                }

                &-name {
                    font-size: 24px;
                    margin: 0 0 5px;
                }
            }
        }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .b-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h3 {
        text-align: center;
        font-size: 1.3rem;
        margin-top: 1em;

        @media (width <= 480px) {
            font-size: 1rem;
        }
    }

    .booking-details-container {
        font-weight: 400;
        max-width: 900px;

        .booking-details-block {
            display: grid;
            flex-basis: 40%;
            grid: 1fr / 1fr 1fr;
            font-size: 1rem;
            margin: 0.3em;

            @media (width <= 480px) {
                font-size: 0.7rem;
            }

            label {
                text-align: right;
                margin-right: 1.2em;
            }

            .small-photo {
                width: 3em;
                height: 3em;
            }

            .psy-photo-container {
                grid-column: 2;
                grid-row: 1 / 3;
                align-self: center;
                justify-self: left;
            }
        }
    }

    .error-message,
    .status-message,
    .info-message {
        text-align: center;
        padding: 0.5em 0.7em;
    }

    .status-message {
        font-size: 1.5em;
        font-weight: 600;
    }

    .account-fields {
        display: grid;
        grid: 1fr / 1fr 1fr;
        font-size: 0.8rem;

        @media (width <= 480px) {
            font-size: 0.7em;
        }

        > :first-child {
            grid-column: 1 / 3;
            text-align: center;
            font-size: 1.1em;
            padding-top: 1.2em;
        }

        > :nth-child(2) {
            grid-column: 1 / 3;
            text-align: center;
            font-size: 1.1em;
            padding-bottom: 1.2em;
        }

        > :nth-child(2n + 3) {
            text-align: right;
            padding-right: 0.5em;
        }

        > :nth-last-child(2) {
            text-align: left;
        }

        > :last-child {
            text-align: right;
            padding-top: 2em;
        }

        img {
            width: 1.5em;
            height: 1.5em;
            padding: 0 0.2em;
        }
    }

    .cancel {
        display: flex;
        justify-content: center;
    }

    .extra-info {
        margin-top: 1rem;
    }

    .pay-container {
        height: unset;
    }
}
