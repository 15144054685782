@import "../tokens";
@import "../common";
@import "btn-base";
@import "btn-base-rounded";
@import "btn-styles";

.button-primary {
    @include btn-base;
    @include button-primary;
}

.button-alter-plus {
    @include btn-base;
    @include alter-plus-gradient-background;

    color: $alter-white;

    &:hover {
        color: $alter-white;
        text-decoration: none;
    }

    &:disabled {
        color: $alter-dark-60;
        background-color: $alter-gray;
    }
}

.button-secondary {
    @include btn-base;

    color: $alter-dark;
    background-color: $alter-white;
    border: 1px solid $alter-dark-30;

    &:hover {
        background-color: transparent;
        border: 1px solid $alter-dark-60;
        line-height: 18px;
        color: $alter-dark;
        text-decoration: none;
    }

    &:focus {
        border: 1px solid $alter-primary-20;
        box-shadow: 0 0 3px $alter-primary-20;
    }

    &:active {
        border: 1px solid $alter-dark;
    }

    &:disabled {
        color: $alter-dark-60;
        background-color: $alter-gray;
        border-color: transparent;
    }
}

.button-secondary-no-border {
    @include btn-base;

    background-color: $alter-white;
    color: $alter-dark-60;
    line-height: 24px;

    &:active {
        box-shadow: inset 0 2px rgb(50 54 72 / 20%);
    }

    &:focus {
        background-color: $alter-white;
        border-color: $alter-dark-20;
    }

    &:disabled {
        background-color: $alter-white-60;
    }
}

.button-danger {
    @include btn-base;

    color: $alter-white;
    background-color: $alter-danger-lite;
    border: 3px solid transparent;

    &:hover {
        background-color: $alter-danger-dark;
    }

    &:focus {
        border: 3px solid $alter-danger-20;
    }

    &:active {
        border-color: $alter-danger-dark;
    }

    &:disabled {
        color: $alter-dark-60;
        background-color: $alter-gray;
    }
}

.button-link {
    @include btn-base;

    color: $alter-dark;
    background-color: $alter-gray-80;

    &:active {
        background-color: $alter-primary-20;
    }

    &:hover {
        text-decoration: underline;
    }
}

.button-link-alter-plus {
    color: $alter-advanced-indigo;

    &:focus {
        color: $alter-advanced-indigo;
    }

    &:hover {
        text-decoration: underline;
        color: $alter-plus-2;
    }
}

.button-primary-rounded {
    @include btn-base-rounded;
    @include button-primary;
}

.button-rounded-danger {
    @include btn-base-rounded;

    color: $alter-white;
    background-color: $alter-danger-lite;

    &:hover {
        color: $alter-white;
        background-color: $alter-danger-dark;
        text-decoration: none;
    }

    &:disabled {
        background-color: $alter-gray;
    }
}

.button-rounded-secondary {
    @include btn-base-rounded;

    color: $alter-primary-dark;
    border: solid 1px $alter-primary-dark;

    &:hover {
        color: $alter-primary-lite;
        text-decoration: none;
        border: solid 1px $alter-primary-lite;
    }

    &:disabled {
        color: $alter-dark-60;
        border-color: $alter-gray;
        background-color: $alter-gray;
    }
}

.button-rounded-alter-plus {
    @include btn-base-rounded;

    color: $alter-white;
    background-color: $alter-plus-1;

    &:hover {
        color: $alter-white;
        background-color: $alter-plus-2;
        text-decoration: none;
    }

    &:disabled {
        background-color: $alter-gray;
    }
}

.button-disabled {
    @include btn-base;
    color: $alter-dark-60;
    background-color: $alter-gray;
    cursor: default;
    pointer-events: none;
    &:hover {
        color: $alter-dark-60;
        background-color: $alter-gray;
        text-decoration: none;
    }
}
