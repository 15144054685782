@import "../../../../node_modules/bulma/sass/utilities/mixins";
@import "@styles-branded/typography";
@import "@styles-branded/colors";

.tag.blm-tag {
    @include default-text;

    height: 2rem;
    border-radius: 4px;
    padding: 4px 8px;
    background-color: $alter-gray;

    &.is-wrap-mobile {
        @include mobile {
            white-space: normal;
            min-height: 2rem;
            height: auto;
            display: block;
            width: 100%;
        }
    }
}
