@use "sass:math";
@import "../../styles-branded/colors";
@import "../../styles-branded/typography";
@import "../../styles-branded/misc";

.dropdown-menu {
    float: unset;
    padding: unset;
    margin: unset;
    font-size: unset;
    color: unset;
    text-align: unset;
    list-style: unset;
    background-color: unset;
    background-clip: unset;
    border: unset;
    border-radius: 8px;
    box-shadow:
        0 64px 144px -8px rgb(0 0 0 / 15%),
        0 24px 48px -8px rgb(0 0 0 / 15%);
}

.dropdown-item {
    display: unset;
    width: unset;
    padding: unset;
    clear: unset;
    font-weight: 400;
    font-size: 1.125rem !important;
    color: unset;
    text-align: unset;
    white-space: unset;
    background-color: unset;
    border: unset;
    cursor: pointer;

    &:active,
    &:hover {
        color: unset;
        text-decoration: unset;
        background-color: unset;
    }

    &.is-highlight-on-hover {
        &:hover,
        &.is-hovered {
            transition: none;
            background-color: $alter-primary-10;
        }
    }
}

.blm-dropdown {
    &:not(.is-old) {
        box-shadow: 0 8px 48px -4px rgba(0 0 0 / 10%);
    }

    border-radius: 8px;

    .dropdown-content {
        padding: 8px;
    }

    .dropdown-item {
        padding: 12px 8px 12px 16px;
        border-radius: 8px;
        transition: $alter-control-transition;
    }

    .dropdown-menu {
        min-width: 100%;
    }

    &.is-fullwidth {
        width: 100%;

        .dropdown-menu {
            width: 100%;
        }
    }

    &.is-active {
        .blm-dropdown__trigger {
            box-shadow: 0 0 0 1px $alter-primary-lite;
        }

        .dropdown-trigger::after {
            border-color: $alter-primary-lite !important;
        }

        .blm-dropdown__icon {
            path {
                fill: $alter-primary-lite;
            }
        }
    }

    &__trigger {
        @extend %control;
        border: solid transparent 1px;
        cursor: pointer;

        &.dropdown-trigger {
            padding-right: 45px;
        }

        .is-fullwidth & {
            width: 100%;
        }

        &.is-arrowless.select.blm-select {
            &::after {
                display: none;
            }
        }
    }

    &__scrollable {
        overflow-y: auto;
    }

    &__label {
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: $weight-normal;
    }

    &__item.dropdown-item {
        font-size: $size-5;
    }

    &__cross {
        position: absolute;
        right: 16px;
        z-index: 4;
        top: 50%;
        transform: translate(0, -50%);

        path {
            fill: $alter-dark-30;
            transition: $alter-control-transition;
        }
    }

    .blm-dropdown__trigger.is-hovered,
    &.is-active .blm-dropdown__trigger {
        border: solid 1px $alter-primary-lite;

        .blm-dropdown__icon {
            path {
                fill: $alter-primary-lite;
            }
        }

        .blm-dropdown__cross {
            path {
                fill: $alter-dark;
            }
        }
    }

    &__icon {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translate(0px, -50%);
        pointer-events: none;

        path {
            fill: rgba(50, 54, 72, 0.3);
        }
    }
}
