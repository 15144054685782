@import "styles/tokens";
@import "ui-kit/styles-branded/misc";

.client-form {
    &__headline {
        color: $alter-dark;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        margin: 0;
        padding-top: 24px;
        padding-bottom: 16px;
        @media (width >= 374px) {
            min-width: 344px;
        }
        @media (width >= 768px) {
            font-size: 30px;
            line-height: 40px;
            padding-top: 48px;
            padding-bottom: 30px;
        }
    }

    &__content {
        margin-top: 24px;
        margin-left: 16px;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        @media (width >= 768px) {
            margin-top: 48px;
        }
    }

    &__card-illustration {
        margin-top: 24px;
        margin-bottom: 16px;
    }

    &__support-link {
        color: $alter-dark-60;
        text-decoration: underline;

        &:hover {
            color: $alter-dark-60;
        }
    }
}

.client-form__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
        width: 100%;
        @media (width >= 768px) {
            background: $alter-gray-50;

            & + * {
                margin-top: 8px;
            }
        }
    }
}

.client-form__go-back {
    color: $alter-dark;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin: 0;
    padding-top: 26px;
    display: flex;
    min-width: 280px;
    cursor: pointer;
    @media (width >= 374px) {
        min-width: 344px;
    }
    @media (width >= 768px) {
        font-size: 30px;
        line-height: 40px;
        padding-top: 48px;
    }

    &__icon {
        margin-right: 8px;
        margin-top: -4px;
        @media (width >= 768px) {
            margin-right: 18px;
            margin-top: -4px;
        }
    }
}

.client-form__tabs {
    display: flex;
    border-bottom: 1px solid $alter-gray;
    @media (width >= 768px) {
        border-bottom: none;
    }

    &__tab {
        color: $alter-dark-60;
        cursor: pointer;
        font-size: 18px;
        font-weight: 500;
        min-width: 139px;

        &--selected {
            color: $alter-primary-lite;
            cursor: default;
            pointer-events: none;
        }
        @media (width >= 768px) {
            font-size: 20px;
            line-height: 24px;
            min-width: 160px;
        }

        &-custom-border {
            margin-top: 14px;
            border-top: 4px solid $alter-primary-lite;
            border-radius: 22px 22px 0 0;
            height: 0;
            width: 122px;
            @media (width >= 768px) {
                margin-top: 22px;
                width: 134px;
            }
        }
    }
}

.client-form__card {
    display: flex;
    flex-direction: column;
    box-shadow: $box-shadow;
    border-radius: 8px;
    padding: 25px 24px;
    margin-bottom: 24px;
    width: 344px;

    &__headline {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: $alter-dark;
    }

    &__text {
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 16px;
    }

    &__input-label {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 18px;
        margin-top: 18px;
    }

    &__form-error {
        margin-top: 10px;
        color: $alter-danger-lite;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
    }
}
