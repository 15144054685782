.fade-ease-out {
    &-enter {
        opacity: 0;
        transform: translateY(24px);
    }

    &-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition:
            opacity 100ms ease-out,
            transform 100ms ease-out;
    }

    &-enter-done {
        opacity: 1;
    }

    &-exit {
        opacity: 1;
        transform: translateY(0);
    }

    &-exit-active {
        opacity: 0;
        transform: translateY(24px);
        transition:
            opacity 100ms ease-out,
            transform 100ms ease-out;
    }
}

.fade-1s {
    &-enter {
        opacity: 0;
    }

    &-active {
        opacity: 1;
        transition: opacity 1000ms;
    }

    &-exit {
        opacity: 1;
    }

    &-exit-active {
        opacity: 0;
        transition: opacity 1000ms;
    }
}
