@import "../../styles-branded/colors";
@import "../../styles-branded/typography";
@import "../../styles-branded/misc";
@import "../../../../node_modules/bulma/sass/form/shared";

.control {
    .help {
        margin-top: 8px;
        color: $alter-dark-60;
    }
}

.blm-select__wrapper {
    position: relative;

    &.is-hovered {
        .blm-dropdown__icon {
            path {
                fill: $alter-primary-lite;
            }
        }
    }
}

.select.blm-select {
    position: relative;
    transition: $alter-control-transition;

    &::after {
        content: none !important;
    }

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        transition: $alter-control-transition;
        background-color: $alter-white;
        border-radius: $input-radius;
        border-color: $alter-gray-80;
    }

    .is-old & {
        &::before {
            background-color: $alter-gray-80;
        }
    }

    select {
        background-color: $alter-white;
        border-color: transparent;
        width: 100%;
        font-size: $size-5;

        &:hover,
        &:active,
        &:focus {
            border-color: $alter-primary-lite;
        }
    }

    &.is-active,
    &.is-focused {
        &::before {
            border-color: $alter-gray;
            background-color: $alter-white;
        }

        &::after {
            .blm-dropdown__icon {
                path {
                    fill: $alter-primary-lite;
                }
            }
        }

        .is-old & {
            &::before {
                background-color: $alter-gray-80;
            }
        }
    }

    &.is-success {
        select {
            border-color: transparent;

            &:hover,
            &:active,
            &:focus {
                border-color: transparent;
            }
        }

        &::before {
            background-color: $alter-primary-10;
            border-color: $alter-primary-10;
        }

        &.is-active,
        &.is-focused {
            &::before {
                border-color: $alter-primary-10;
            }
        }
    }

    &.is-disabled {
        pointer-events: none;
    }
}

.blm-button.button.is-loading::after {
    border-color: $alter-dark;
    border-right-color: transparent;
    border-top-color: transparent;
}

.blm-button.button {
    background-color: var(--alter-control-default-bg-color);
    transition: $alter-control-transition;
    text-decoration: none;
    font-weight: $weight-medium;
    border-color: var(--alter-control-default-border-color);

    &:hover,
    &:active,
    &:focus,
    &.is-hovered {
        color: unset;
        border-color: unset;
        background-color: var(--alter-control-hover-default-bg-color);
    }

    &.is-primary {
        background-color: var(--alter-control-primary-content-color);

        &:hover,
        &:active,
        &:focus,
        &.is-hovered {
            background-color: var(--alter-control-primary-content-color-hover);
        }

        &[disabled] {
            color: $alter-dark-60;
            opacity: 1;
            border-color: $alter-gray;
            background-color: $alter-gray;
        }
    }

    &.is-danger {
        background-color: $alter-danger-dark;

        &:hover,
        &:active,
        &:focus,
        &.is-hovered {
            background-color: $alter-danger-lite;
        }

        &[disabled] {
            color: $alter-dark-60;
            opacity: 1;
            border-color: $alter-gray;
            background-color: $alter-gray;
        }
    }

    &.is-borderless {
        border-color: $alter-white;
        box-shadow: 0 8px 48px -8px rgba(0, 0, 0, 0.15);

        &:hover,
        &:active,
        &:focus,
        &.is-hovered {
            border-color: $alter-gray-80;
            background-color: $alter-gray-80;
        }
    }

    &.is-like-link {
        font-weight: inherit;
        appearance: unset;
        background-color: transparent;
        color: $link;
        border: none;
        padding: 0;
        cursor: pointer;
        height: auto;

        &:hover,
        &:active,
        &:focus,
        &.is-hovered {
            color: $link-hover;
        }
    }

    &.is-inline-link {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus,
        &.is-hovered {
            color: inherit;
            filter: brightness(50%);
        }
    }

    &.is-responsive {
        width: 100%;

        @include tablet {
            width: auto;
        }
    }

    &[disabled] {
        cursor: default;
        color: $alter-dark-60;
        background: $alter-gray;
        border: none;
        opacity: 1;
    }

    &.has-small-gaps {
        padding: 4px 8px;
        height: auto;
        max-height: 32px;
        font-weight: $weight-normal;
        border-radius: 4px;
    }
}

.blm-input {
    &__spinner {
        position: absolute;
        right: 16px;
        top: 12px;
    }

    & + svg {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 16px;
        top: 12px;
    }

    &.is-danger + svg {
        path {
            fill: $alter-danger-lite;
        }
    }
}

.blm-input,
.textarea {
    &:focus {
        caret-color: $alter-primary-lite;
        &::placeholder {
            color: $alter-dark-60;
        }
    }
}
