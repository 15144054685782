@import "../../../../styles/colors";
@import "../../../../styles/tokens";

.booking-cards {
    &__wrapper {
        flex-direction: column;

        @media (width <= 992px) {
            gap: 24px;
        }
        @include screen-size(desktop) {
            flex-direction: row;
        }
    }

    &__cards {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 0 16px;
        flex-flow: wrap;
        @include screen-size(desktop) {
            align-items: stretch;
            flex-direction: row;
            width: 704px;
            margin-bottom: 32px;
        }
    }

    &__text-and-icon {
        display: flex;
        flex-direction: column;
        padding: 0 0 24px;

        &-subject {
            color: $alter-dark;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            @include screen-size(desktop) {
                font-size: 24px;
                line-height: 32px;
                margin-right: 32px;
            }
        }

        &-price {
            color: rgb(50 54 72 / 60%);
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            @include screen-size(desktop) {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }

    &__card {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        position: relative;
        box-shadow: $box-shadow;
        border-radius: 8px;
        width: 100%;
        padding: 16px;
        justify-content: space-between;
        border: 2px solid transparent;
        transition: border-color 0.2s;
        @include screen-size(desktop) {
            padding: 24px 32px;
            margin-top: 8px;
        }

        &__with-discount {
            min-height: 160px !important;
            @include screen-size(desktop) {
                min-height: 0;
            }
        }

        &.is-single {
            display: flex;
            flex-direction: row;
            cursor: pointer;
            position: relative;
            box-shadow: $box-shadow;
            border-radius: 8px;
            width: 100%;
            padding: 16px 14px;
            justify-content: space-between;
            margin-bottom: 16px;
            min-height: 0;
            margin-top: 0;
        }

        &.is-selected {
            border: 2px solid $alter-primary-lite;
            cursor: auto;
        }

        &.is-disabled {
            pointer-events: none;
            @include screen-size(desktop) {
                max-width: 672px;
            }

            .booking-cards__text-and-icon,
            img {
                opacity: 0.4;
            }
        }

        &-illustration {
            width: 130px;
            height: 104px;
            align-self: flex-end;
            @media (width >= 360px) {
                width: 180px;
                height: 104px;
            }
            @include screen-size(desktop) {
                width: 264px;
                height: 152px;
                align-self: auto;
            }
        }

        &__icon {
            position: absolute;
            left: 14px;
            bottom: 15px;
            transition: opacity 0.2s ease-out;
            opacity: 0;

            &-square {
                inset: 22px 30px auto auto;
            }

            &__one-element {
                @include screen-size(desktop) {
                    inset: auto auto 22px 30px;
                }

                transition: opacity 0.2s ease-out;
                opacity: 0;

                &.is-selected {
                    opacity: 1;
                }
            }

            &.is-selected {
                opacity: 1;
            }
        }
    }

    &__card-square {
        flex-direction: column;
        min-height: 345px;
        width: calc(50% - 8px);
        margin-top: 0;
    }

    &__session-info {
        flex-direction: column;
        box-shadow: $box-shadow;
        border-radius: 8px;
        padding: 24px 26px;
        display: none;
        margin-bottom: 24px;
        width: 100%;
        @include screen-size(desktop) {
            display: block;
        }

        &-headline {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: $alter-dark;
            margin-bottom: 10px;
        }

        &-description {
            border-bottom: 1px solid $alter-gray;
            padding-bottom: 16px;
        }

        &-family-or-single-person {
            font-weight: 400;
            font-size: 18px;

            &::first-letter {
                text-transform: capitalize;
            }
            &.is-no-first-letter {
                &::first-letter {
                    text-transform: none;
                }
            }
        }

        &-price {
            font-weight: 400;
            font-size: 18px;
            margin-top: 18px;
            margin-bottom: 18px;
            display: flex;
            align-items: center;

            &-discount {
                white-space: nowrap;
                margin-left: 4px;
                margin-top: 3px;
                font-size: 16px;
                color: rgb(50 54 72 / 60%);
                position: relative;
                font-weight: 400;
                @include screen-size(desktop) {
                    margin-left: 11px;
                    margin-top: 0;
                }

                &-column-discount {
                    margin-left: 4px;
                    margin-top: 3px;
                    @include screen-size(desktop) {
                        margin-left: 4px;
                        margin-top: 6px;
                        font-size: 18px;
                    }
                }
            }

            &-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @include screen-size(desktop) {
                    flex-direction: row;
                    align-items: center;

                    &.is-nowrap-desktop {
                        white-space: nowrap;
                    }
                }

                &-column-discount {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            &.isDeposit {
                flex-wrap: wrap;
            }
        }

        &-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            @media (width <= 992px) {
                width: auto;
                margin: 0 16px;
            }
            @include screen-size(desktop) {
                display: block;
                width: 320px;
                min-width: 320px;
                margin-left: 16px;
            }
        }
    }

    &__not-available-button {
        margin-top: 8px;
        max-width: none;
        padding-left: 16px;
        padding-right: 16px;
        @include screen-size(desktop) {
            max-width: 704px;
        }

        &--hover {
            display: block;

            &:hover {
                text-decoration: none;
                color: white;
            }
        }
    }
}
