@import "../../../../../node_modules/bulma/sass/utilities/mixins";
@import "../../../../ui-kit/components-branded/Headline/styles";
@import "../../../../styles/tokens";

.promoted-psys {
    padding-top: 56px;
    padding-bottom: 48px;
    width: 100%;
    font-weight: 400;
    background: #fef1df;
    margin: 0 0 24px;

    @include tablet {
        margin: 24px 0 32px;
    }

    &__container {
        flex: 1 0 auto;
        width: 100%;
        padding-left: 9px;
        @media (width >= 640px) {
            max-width: 620px;
            margin: 0 auto;
        }
        @media (width >= 954px) {
            max-width: 980px;
            margin: 0 auto;
        }
    }

    &__head {
        position: relative;
        margin-bottom: 16px;
        @include mobile {
            padding-left: 8px;
        }
    }

    &__title {
        @include headline3-bold;
    }

    &__arrows {
        position: absolute;
        right: 0;
        top: calc(50% - 5px);
        display: none;
        align-items: center;

        &.--show {
            display: flex;
        }
    }

    &__arrow {
        padding: 0 3px;
        cursor: pointer;
        user-select: none;

        path {
            fill: $alter-dark-20;
        }

        &.is-active {
            path {
                fill: $alter-primary-lite;
            }
        }
        @media (width >= 640px) {
            padding: 0 12px;
        }
    }

    &__list {
        display: flex;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__item {
        margin: 8px !important;
        border-radius: 8px;
        width: 229px;
        box-shadow: 0 4px 16px -8px rgb(0 0 0 / 15%);
    }

    &__specialization {
        font-size: 18px;
        line-height: 24px;
        @include mobile {
            text-align: left;
        }
    }

    &__additional-info {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 24px;
    }
}
