.price-and-time {
    color: rgb(50 54 72 / 60%);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &__first {
        display: inline-flex;
        white-space: nowrap;
        @media (width <= 992px) {
            width: 100%;
        }
    }

    &__discount {
        margin-left: 6px;
        color: rgb(50 54 72 / 60%);
        position: relative;
        font-weight: 400;
        margin-right: 4px;

        &__container {
            display: flex;
            align-items: center;
            white-space: nowrap;
        }
    }
}
