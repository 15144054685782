@import "../../styles/colors";
@import "../../../node_modules/bulma/sass/utilities/mixins";

.header-discount-badge {
    margin-right: 18px;
    color: color-get("blue", 4);
    display: flex;

    &__tooltip-handle {
        margin-left: 10px;
    }

    &__text {
        background-color: rgba(41 185 129 / 20%);
        border-radius: 4px;
        padding: 0 4px;
        display: none;
        font-size: 18px;
        line-height: 24px;
    }

    @include mobile {
        &__mobile {
            display: flex;
        }
    }

    @include tablet {
        &__desktop {
            display: flex;
        }
    }
}
