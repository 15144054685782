@use "sass:math";
@import "../../styles/colors";
@import "../../styles/tokens";
@import "../../ui-kit/styles-branded/typography";

.rc-tooltip {
    background-color: transparent !important;
}

.redesign-container {
    flex: 1 0 auto;
    width: 100%;
    max-width: 1024px + 32px;
    margin: 0 auto;
}

.survey-results-header {
    font-weight: 500;
    font-size: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid color-get("gray", 2);
    @media (width >= 768px) {
        border: none;
        justify-content: flex-start;
        height: 64px;
    }

    &__wrapper {
        background: white;
        width: 100%;
        @media (width >= 768px) {
            border-bottom: 1px solid color-get("gray", 2);
        }
    }

    &__requests {
        font-size: 18px;
        line-height: 24px;
        @media (width >= 768px) {
            font-size: 24px;
            line-height: 32px;
        }

        &__emoji {
            width: 24px;
            @media (width >= 768px) {
                width: 32px;
            }
        }

        &.is-accent-mobile {
            font-weight: $weight-medium;
            @media (width >= 768px) {
                font-weight: $weight-normal;
            }
        }
    }

    &__secondary-requests {
        padding-top: 8px;
        margin-top: 8px;
        border-top: 1px solid color-get("gray", 2);
    }

    &__logo {
        width: max-content;
        color: $alter-primary-lite;

        &:hover {
            text-decoration: none;
        }
    }
}

.survey-results-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    box-shadow: $box-shadow;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
    width: 100%;
    @media (width >= 768px) {
        margin-bottom: 32px;
    }

    &__illustration {
        margin-bottom: 16px;
        width: 296px;
        height: 168px;

        &-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            margin-top: 24px;
        }
    }

    &__nobody-liked {
        justify-content: space-between;

        &-headline {
            font-weight: 500;
            margin-bottom: 8px;
        }
    }

    &__more-details-button {
        line-height: 14px;
    }
}

.survey-results {
    line-height: 24px;
    font-size: 18px;
    font-weight: normal;
    color: color-get("blue", 4);
    margin-left: 16px;
    margin-right: 16px;

    &__heading-container {
        width: 100%;
        margin-top: 24px;
        margin-bottom: 8px;

        @media (width >= 768px) {
            padding: 32px 0;
            background-color: $alter-gray-80;
            margin-top: 0;
            margin-bottom: 56px;

            & + .redesign-container .survey-results__requests-container {
                margin-top: 48px;
            }
        }
    }

    &__heading {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        padding: 0;
        color: rgb(50 54 72 / 100%);
        margin-left: 16px;
        margin-right: 16px;
        @media (width >= 768px) {
            font-size: 30px;
        }
    }

    &__cards {
        margin-top: 24px;
        @media (width >= 768px) {
            margin-top: 56px;
        }
    }

    &__warning {
        padding: 16px;
        background-color: #f8bb60;
        border-radius: 8px;
        font-weight: normal;
        margin-bottom: 56px;
        @media (width >= 768px) {
            font-weight: 500;
        }

        &__link {
            color: inherit;
            text-decoration: underline;

            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }
    }

    &__labels {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 8px;

        &-bigger {
            @media (width >= 640px) {
                min-height: 56px;
            }
        }

        &-smaller {
            @media (width >= 640px) {
                min-height: 24px;
            }
        }

        &:empty {
            display: none;
        }
    }
}

.contact-request-popup {
    &__input {
        caret-color: $alter-primary-lite;
        background: #fff;
        border: 1px solid $alter-dark-30;
        border-radius: 8px;
        height: 48px;
        display: block;
        width: 100%;
        padding: 12px 16px;
        outline: none;
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -webkit-appearance: none;

        &:disabled {
            background: $alter-gray-80;
        }

        &-container {
            margin-bottom: 16px;
        }

        &-error {
            border: 1px solid color-get("red", 1) !important;
        }

        &::placeholder {
            color: rgba(50 54 72 / 30%);
        }

        &:focus {
            border: 1px solid color-get("green", 2);
            box-shadow: none;

            :not(:focus-visible) {
                outline: none;
            }
        }

        &-error-notification {
            color: color-get("red", 1);
            font-size: 16px;
            margin-top: 8px;
        }
    }
}
