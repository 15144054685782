@import "variables";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";

.font-futura-400,
%font-futura-400 {
    font-family: FuturaPT, sans-serif;
    font-weight: 400;
}

.font-futura-500,
%font-futura-500 {
    font-family: FuturaPT, sans-serif;
    font-weight: 500;
}

.font-futura-600,
%font-futura-600 {
    font-family: FuturaPT, sans-serif;
    font-weight: 600;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $number, $size in $font-sizes {
            .font-size#{$infix}-#{$number},
            %font-size#{$infix}-#{$number} {
                font-size: $size !important;
            }
        }
    }
}

@each $number, $weight in $font-weights {
    .font-weight-#{$number},
    %font-weight-#{$number} {
        font-weight: $weight !important;
    }
}

@each $number, $height in $line-heights {
    .line-height-#{$number},
    %line-height-#{$number} {
        line-height: $height !important;
    }
}

@each $t in $transform {
    .t-trans-#{$t} {
        text-transform: #{$t} !important;
    }
}

@each $d in $decoration {
    .t-decor-#{$d} {
        text-decoration: #{$d} !important;
    }
}

@each $ta in $text-align {
    .t-align-#{$ta} {
        text-align: #{$ta} !important;
    }
}
