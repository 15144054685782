@import "../../../../ui-kit/components-branded/Headline/styles";

.payment-method {
    &__content {
        display: flex;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
        }
    }

    &__title {
        font-weight: 450;
        @include mobile {
            @include headline5-medium;
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__subtext {
        flex: 1;
        margin-bottom: 16px;
        @include mobile {
            margin-bottom: 32px;
        }
    }

    &__illustration {
        width: 211px;
        height: 120px;

        @include mobile {
            height: auto;
            width: 100%;
            margin-bottom: 16px;
        }
    }
}
