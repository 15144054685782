@import "variables";

.bg-lightgrey {
    background-color: $alter-lightgrey-background;
}

@each $color-name, $value in $palette {
    @if type-of($value) == map {
        @each $number, $shade in $value {
            .color-#{$color-name}-#{$number} {
                color: $shade !important;
            }

            .hover-#{$color-name}-#{$number} {
                &:hover {
                    color: $shade !important;
                }
            }

            .bg-#{$color-name}-#{$number} {
                background-color: $shade !important;
            }

            @each $cls, $prop in $border {
                .#{$cls}-#{$color-name}-#{$number} {
                    #{$prop}: 1px solid $shade;
                }
            }
        }
    }

    @if type-of($value) == color {
        .color-#{$color-name} {
            color: $value !important;
        }

        .hover-#{$color-name} {
            &:hover {
                color: $value !important;
            }
        }

        .bg-#{$color-name} {
            background-color: $value !important;
        }

        @each $cls, $prop in $border {
            .#{$cls}-#{$color-name} {
                #{$prop}: 1px solid $value;
            }
        }
    }
}

@function color-get($shade, $number) {
    @return map-get(map-get($palette, $shade), $number);
}
