@import "../../../styles/colors";
@import "../../../styles/tokens";
@import "../../../../node_modules/bulma/sass/utilities/mixins";

.after-payment {
    display: flex;
    color: $alter-dark;
    flex-direction: column;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 12px;

    &__left {
        margin-bottom: 24px;

        @include screen-size(desktop) {
            min-width: 672px;
            max-width: 672px;
            margin-right: 32px;
            margin-bottom: initial;
        }
    }

    @include screen-size(desktop) {
        flex-direction: row;
        margin-top: 0;
    }

    &__session-details {
        padding: 16px;
        background: rgb(41 185 129 / 10%);
        border-radius: 8px;
        font-weight: normal;
        font-size: 16px;
        margin-top: 5px;
        height: max-content;
        margin-bottom: 24px;
        @include screen-size(desktop) {
            width: 100%;
            font-size: 18px;
            margin-top: 0;
            margin-bottom: 32px;
        }

        &__bullet {
            margin-right: 4px;
            margin-left: 3px;
            @include screen-size(desktop) {
                margin-right: 5px;
            }
        }

        &__text {
            display: flex;
            align-items: center;
            margin-bottom: 6px;
            @include screen-size(desktop) {
                margin-bottom: 4px;
            }
        }
    }

    &__banners {
        display: flex;
        flex-direction: column;
    }

    &__gift-certificate {
        color: $alter-dark;
        padding: 16px;
        background: #fcefe9;
        border-radius: 8px;
        min-height: 128px;
        height: max-content;
        font-weight: 500;
        font-size: 16px;
        position: relative;
        cursor: pointer;
        margin-bottom: 24px;
        @include screen-size(desktop) {
            font-size: 18px;
            margin-bottom: 32px;
        }

        &:hover {
            color: $alter-dark;
            text-decoration: none;

            .after-payment__gift-certificate-link {
                color: $alter-primary-dark;
                text-decoration: underline;
            }
        }

        &::after {
            content: "";
            position: absolute;
            width: 178px;
            height: 59px;
            background-image: url("/images/gifts_illustration.svg");
            bottom: 0;
            right: 0;
            border-radius: 0 0 8px;
        }

        &__text {
            margin-bottom: 4px;
            margin-left: 2px;
            line-height: 24px;
        }

        &__link {
            margin-bottom: 4px;
            margin-left: 2px;
            color: $alter-primary-lite;
            text-decoration: underline;
        }
    }

    &__session-info {
        display: flex;
        flex-direction: column;
        box-shadow: $box-shadow;
        border-radius: 8px;
        padding: 24px 32px;
        margin-bottom: 32px;
        width: 100%;

        @media (width <= 991px) {
            margin-bottom: 24px;
        }

        &-bullet {
            color: $alter-dark-20;
            margin: 0 4px;
        }

        &-divider {
            width: 0;
            border-left: 1px solid color-get("gray", 2);
            height: 152px;
            display: none;
            margin-left: 60px;
            margin-right: 23px;
            @include screen-size(desktop) {
                display: block;
            }
        }

        &-top {
            display: flex;
            flex-direction: column;
            @include tablet {
                flex-direction: row;
                align-items: center;
            }
        }

        &__header {
            display: flex;
            flex-direction: row;
            color: $alter-dark;

            &:hover {
                cursor: pointer;
                text-decoration: none;
                color: $alter-dark;
            }

            &__avatar {
                height: 48px;
                min-width: 48px;
                max-width: 48px;
                display: flex;
                margin-right: 16px;

                &::before {
                    width: 48px;
                    height: 48px;
                    position: absolute;
                    z-index: 1;
                    border-radius: 50%;
                    content: "";
                    box-shadow: inset 0 0 0 1px $alter-dark-20;
                }

                img {
                    border-radius: 50%;
                    min-height: 100%;
                    min-width: 100%;
                }
            }

            &__main-info {
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                line-height: 22px;

                &__name {
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: normal;
                }

                &__title {
                    color: rgb(50 54 72 / 60%);
                    font-size: 18px;
                    font-weight: 400;
                    max-width: 180px;

                    &::first-letter {
                        text-transform: capitalize;
                    }
                }
            }
        }

        &__line {
            border-bottom: 1px solid color-get("gray", 2);

            @include mobile {
                opacity: 0;
            }
        }

        &__bottom {
            display: flex;
            flex-direction: column;
            margin: 16px 0 0;

            & > *:not(:last-child) {
                margin: 0 0 16px;
            }

            @include screen-size(desktop) {
                flex-direction: row;

                & > *:not(:last-child) {
                    margin: 0 16px 0 0;
                }
            }
        }

        &__location {
            &-icon {
                margin-right: 3px;
                margin-top: -6px;
            }
        }
    }

    &__browser-waring {
        margin-bottom: 24px;
        @include screen-size(desktop) {
            margin-bottom: 32px;
            margin-right: 32px;
            width: 100%;
        }
    }

    &__modal {
        &__links {
            margin-top: 24px;
            display: flex;
            flex-wrap: wrap;
            width: 170px;
            @include screen-size(desktop) {
                width: auto;
            }

            &__link-with-margin {
                margin-bottom: 16px;
                @include screen-size(desktop) {
                    margin: 0;
                }
            }

            a {
                color: $alter-dark;
                margin-right: 24px;
                display: flex;
                align-items: center;

                &:hover {
                    text-decoration: none;
                    color: $alter-primary-dark;
                }

                span {
                    margin-left: 8px;
                    text-decoration: underline;
                }
            }
        }

        &__session-link {
            text-decoration: underline;
            color: $alter-dark;
        }
    }

    &__last-widget {
        @include screen-size(desktop) {
            margin-bottom: 32px;
        }
    }
}
/* stylelint-disable-next-line selector-class-pattern */

.partners-banner {
    &.box.blm-box {
        padding: 16px;
        color: $alter-white;
        background: $alter-primary-lite url("/images/artwork.png") no-repeat bottom right;
        background-size: 100%;
        text-decoration: none;
        border: none;
        box-shadow: none;

        @include tablet-only {
            background-size: contain;
        }
    }

    &__button.button {
        width: 100%;
        background-color: rgb(255 255 255 / 10%) !important;
        backdrop-filter: blur(8px);
        color: $alter-white !important;
        border-color: $alter-white !important;
        box-shadow: 0 10px 32px rgb(0 0 0 / 20%);
        margin-top: 48px;
    }

    &:hover {
        .partners-banner__button.button {
            background-color: rgb(255 255 255 / 20%) !important;
            backdrop-filter: blur(16px);
        }
    }
}

.no-wrap-on-desktop {
    white-space: normal !important;

    @include tablet {
        white-space: nowrap !important;
    }
}

.session-info-top__with-divider {
    @include tablet {
        border-right: 1px solid color-get("gray", 2);
        margin-right: -9px;
    }
}

.psy-info__with-divider {
    border-top: 1px solid color-get("gray", 2);
    margin-top: -16px;
    padding-top: 16px;

    @include tablet {
        border-top: none;
        margin-top: initial;
    }
}
