@import "../../styles/tokens";

.tabs {
    &__color-alter-plus {
        color: $alter-advanced-indigo;
    }

    &__background-alter-plus {
        background-color: $alter-advanced-indigo;
    }
}
