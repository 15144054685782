@use "sass:color";
@import "../../../../../node_modules/bulma/sass/utilities/mixins";
@import "../../../../styles/tokens";

.mobile-navigation {
    &__opener {
        position: relative;
        cursor: pointer;
    }

    &__menu {
        transition: opacity 0.2s ease-out;
        opacity: 1;
        height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        color: $alter-white;
        background-color: $alter-primary-lite;
    }

    &__menu-hidden {
        opacity: 0;
        height: 0;
        overflow: hidden;
        min-height: unset;

        & + .mobile-navigation__opener {
            z-index: 40;
        }
    }

    &__header {
        position: relative;
    }

    &__title {
        @include alter-universal-body-text-accent;

        font-size: 29px;
        height: 56px;
        line-height: 56px;
        padding: 0 16px;
        text-align: center;
    }

    &__close {
        position: absolute;
        top: 16px;
        right: 23px;
        cursor: pointer;
    }

    &__subheader-item {
        padding: 8px 16px;

        a.button-secondary {
            color: $alter-dark;
            width: 100%;
        }

        a.button-primary {
            background-color: $alter-primary-dark;
            border-color: $alter-primary-20;
            border-width: 1px;
            width: 100%;
        }

        &:first-of-type {
            padding-top: 24px;
        }

        &:last-of-type {
            padding-bottom: 24px;
        }
    }

    &__item-is-light a {
        line-height: 40px;
        font-weight: 400;
    }

    &__item a {
        padding: 0 16px;
        line-height: 56px;
        display: block;
        height: 100%;
        width: 100%;
        color: $alter-white;
        text-decoration: none;

        &:hover {
            color: $alter-white;

            svg {
                margin-left: 6px;
            }
        }
    }

    &__item {
        @include alter-universal-body-text-accent;
    }

    &__item:has(a:active) {
        background-color: color.adjust($alter-primary-lite, $blackness: 10%);
    }

    &__item button {
        font-weight: inherit;
        font-size: inherit;
        color: $alter-white;
        padding: 0;

        svg {
            margin-left: 6px;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    &__submenu-item a {
        padding: 0 16px;
        line-height: 56px;
        display: block;
        height: 100%;
        width: 100%;
        color: $alter-white;

        &:hover {
            color: $alter-white;
        }
    }

    &__item-dropdown {
        padding: 0 16px;
        line-height: 56px;
    }

    &__highlight {
        background-color: $alter-primary-dark;
    }

    &__highlight &__item:has(a:active) {
        background-color: color.adjust($alter-primary-dark, $blackness: 10%);
    }

    &__line {
        margin: 0 16px;
        background-color: $alter-white-30;
        height: 1px;
    }

    &__submenu {
        transition: opacity 0.3s ease-out;
        opacity: 1;
        height: auto;
        @include alter-universal-body-text;
    }

    &__submenu-hidden {
        opacity: 0;
        height: 0;
        overflow: hidden;
    }
}
