@import "../../../styles/tokens";

.rc-slider-wrapper {
    padding: 0 10px;
}

.rc-slider-rail,
.rc-slider-track {
    height: 12px;
}

.rc-slider-track {
    background-color: $alter-primary-lite;
}

.rc-slider-handle,
.rc-slider-handle:focus,
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: 0 0 4px 4px $alter-dark-20;
    border: none;
    background-color: $alter-white;
    opacity: 1;
    width: 20px;
    height: 20px;
}
