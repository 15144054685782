@import "ui-kit/styles-branded/colors";

.club-banner {
    background: #232323;
    border-radius: 8px 16px 16px 8px;
    height: 128px;
    position: relative;

    &__description {
        color: $alter-white;
        position: absolute;
        left: 16px;
        top: 16px;
    }

    &__club {
        color: #7b79fb;
    }

    &__image {
        border-radius: 8px;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        overflow: hidden;
    }
}
