@use "sass:color";

$alter-primary-lite: #29b981;
$alter-primary-dark: #26aa76;
$alter-primary-20: #d4f1e6;
$alter-primary-10: color.change($alter-primary-lite, $alpha: 0.1);
$alter-danger-lite: #ef6a5f;
$alter-danger-dark: #db6258;
$alter-danger-20: color.change($alter-danger-lite, $alpha: 0.2);
$alter-advanced-indigo: #4f5fab;
$alter-advanced-yellow: #f8bb60;
$alter-advanced-yellow-20: color.change($alter-advanced-yellow, $alpha: 0.2);
$alter-dark: #323648;
$alter-dark-60: color.change($alter-dark, $alpha: 0.6);
$alter-dark-30: color.change($alter-dark, $alpha: 0.3);
$alter-dark-20: color.change($alter-dark, $alpha: 0.2);
$alter-gray: #f0eeeb;
$alter-gray-80: #f6f6f5;
$alter-gray-50: color.change($alter-gray, $alpha: 0.5);
$alter-gray-20: color.change($alter-gray, $alpha: 0.2);
$alter-gray-10: color.change($alter-gray, $alpha: 0.1);
$alter-white: #fff;
$alter-white-60: color.change($alter-white, $alpha: 0.6);
$alter-white-30: color.change($alter-white, $alpha: 0.3);
$alter-black: #000;
$alter-black-60: color.change($alter-black, $alpha: 0.6);
$alter-plus-1: #2946d1;
$alter-plus-2: #2b3e9c;
$box-shadow: 0 8px 48px -8px rgb(0 0 0 / 15%);

// TYPOGRAPHY
@mixin alter-universal-body-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
}

@mixin alter-universal-body-text-accent {
    @include alter-universal-body-text;

    font-weight: 500;
}

@mixin alter-universal-secondary {
    @include alter-universal-body-text;

    font-size: 16px;
}

@mixin alter-desktop-headline-4 {
    @include alter-universal-body-text-accent;

    font-size: 20px;
}

@mixin alter-desktop-headline-3-medium {
    @include alter-universal-body-text-accent;

    font-size: 20px;
}

@mixin screen-size($point) {
    @if $point == desktop {
        @media (width >= 992px) {
            @content;
        }
    }
    @if $point == tablet {
        @media (width < 992px) {
            @content;
        }
    }
}
