@import "../../../../../../../styles/tokens";

.pie-chart {
    position: relative;
    margin-left: -10px;
}

.pie-chart-count-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}

.pie-chart-count {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 52px;
    padding: 0 0 20px;
    font-weight: 500;

    &.low-value {
        color: $alter-danger-lite;
    }

    &-left {
        padding-right: 5px;
    }
}

.circle-shadow-green {
    filter: drop-shadow(0 8px 48px rgb(41 187 129 / 30%));
}

.circle-shadow-red {
    filter: drop-shadow(0 8px 48px rgb(239 106 95 / 20%));
}

.pie-chart-circle-progress {
    position: absolute;
    top: 0;
    left: 0;
}
