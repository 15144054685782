@import "../../../../../styles/tokens";
@import "../../../../../../node_modules/bulma/sass/utilities/mixins";

.sign-up-again-card {
    &__content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;

        @include mobile {
            flex-direction: column;
            margin-bottom: 32px;
        }
    }

    &__description {
        padding-right: 23px;
        flex-basis: 50%;
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            height: 100%;
            width: 1px;
            background: $alter-gray;
            right: 10.5px;
            @include mobile {
                display: none;
            }
        }

        @include mobile {
            padding-right: 0;
            border-bottom: 1px solid $alter-gray;
            padding-bottom: 16px;
            margin-bottom: 16px;
        }
    }

    &__title {
        @include mobile {
            font-size: 18px !important;
        }
    }

    &__profile {
        padding-left: 23px;
        flex-basis: 50%;
        @include mobile {
            padding-left: 0;
        }
    }

    &__line {
        margin-bottom: 16px;
        width: 100%;
        height: 1px;
        background: $alter-gray;
        @include mobile {
            display: none;
        }
    }
}
