@import "../../../../node_modules/bulma/sass/utilities/mixins";
@import "../../../styles/tokens";

$section-padding: 3rem 1rem !default;

.blm-box {
    &.box {
        background-color: var(--alter-secondary-bg-color);
        color: var(--alter-primary-text-color);

        &.has-bg-gray {
            background-color: $alter-gray;
        }

        &.has-bg-advanced-yellow {
            background-color: $alter-advanced-yellow;
        }

        @include tablet {
            padding: 24px 32px;

            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }

        &.is-small-gaps {
            padding: 16px;

            @include tablet {
                padding: 24px;
            }
        }

        &.has-constant-gaps {
            padding: 24px;

            &.is-small-gaps {
                padding: 16px;
            }

            &.is-big-gaps {
                padding: 32px;
            }
        }

        &.has-constant-small-gaps {
            padding: 16px;
        }

        &.is-fullheight {
            height: 100%;
        }
    }
}

.container {
    &.is-fullwidth {
        width: 100%;
    }
}

.columns {
    &.has-small-gaps {
        margin-left: -8px;
        margin-right: -8px;
        margin-top: -8px;

        &:last-child {
            margin-bottom: -8px;
        }

        .column {
            padding: 8px;
        }
    }
}
