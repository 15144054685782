@import "../../../../../../node_modules/bulma/sass/utilities/mixins";
@import "../../../../../styles/tokens";

.pie-chart-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 125px;
    padding-top: 15px;

    & > div {
        position: absolute;
    }
}

.psy-index-quality {
    width: 496px;
    min-height: 386px;
    padding: 32px 16px 16px;
    border-radius: 8px;
    border: 1px solid $alter-dark-20;

    @media (width <= 550px) {
        width: 100%;
        margin: 0 20px;
    }

    &__text-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;

        & > span {
            text-align: center;
        }
    }

    .psy-link-button {
        text-align: center;
        text-decoration: none;
    }
}

.psy-idx-spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
