@import "../../../../styles/tokens";

.booking-psy-avatar {
    border-radius: 50%;
    width: 32px;
    height: 32px;

    @include screen-size(desktop) {
        width: 40px;
        height: 40px;
    }

    img {
        border-radius: 50%;
        min-width: 100%;
        min-height: 100%;
    }
}
