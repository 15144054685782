@import "../../styles/colors";
@import "../../styles/tokens";
@import "../../../node_modules/bulma/sass/utilities/mixins";

/* stylelint-disable-next-line selector-class-pattern */
.b2b-banner {
    display: block;
    color: $alter-dark;
    padding: 16px;
    background: $alter-advanced-yellow-20;
    border-radius: 8px;
    min-height: 128px;
    height: max-content;
    font-weight: 500;
    font-size: 18px;
    margin-top: 5px;
    position: relative;
    cursor: pointer;
    margin-bottom: 0;
    @include screen-size(desktop) {
        margin-top: 0;
        margin-bottom: 32px;
    }

    &__link {
        color: $alter-primary-lite;
        text-decoration: underline;
        margin-bottom: 26px;
    }

    &:hover {
        color: $alter-dark;
        text-decoration: none;
        /* stylelint-disable-next-line selector-class-pattern */
        .b2b-banner__link {
            color: $alter-primary-dark;
            text-decoration: underline;
        }
    }

    &::after {
        content: "";
        position: absolute;
        background-image: url("/images/b2b_suggestion_illustration.png");
        bottom: 0;
        right: 0;
        background-repeat: no-repeat;
        background-position: right top;
        transform: scaleX(-1);
        width: 118px;
        height: 118px;
        background-size: 145px 131px;
        border-radius: 0 0 0 8px;
    }

    &__headline {
        line-height: 24px;
        position: relative;
        z-index: 1;
    }

    &__text {
        margin-bottom: 2px;
        line-height: 24px;
        position: relative;
        z-index: 1;
        font-weight: 400;
    }
}
