@import "../../../styles/tokens";
@import "../../../../node_modules/bulma/sass/utilities/mixins";

.blm-tabs {
    margin-left: 4px;

    &__tab {
        @include alter-universal-body-text-accent;

        color: $alter-dark-60;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 20px;
        }

        @include desktop {
            @include alter-desktop-headline-3-medium;
        }

        &--active {
            color: $alter-primary-lite;
            cursor: default;
            pointer-events: none;
        }

        &--disabled {
            cursor: default;
            pointer-events: none;
            color: $alter-dark-20;
        }

        &-custom-border {
            margin-top: 14px;
            border-top: 4px solid $alter-primary-lite;
            border-radius: 22px 22px 0 0;
            height: 0;
            @include tablet {
                margin-top: 22px;
            }

            &-visibility-hidden {
                visibility: hidden;
            }
        }
    }
}
