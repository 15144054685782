@import "variables";
@import "tokens";

.rc-tooltip {
    border-radius: 8px !important;
    opacity: 1 !important;
    z-index: 1020 !important;

    @include screen-size(tablet) {
        left: 8px !important;
        right: 8px !important;
    }

    .rc-tooltip-inner {
        background-color: var(--alter-secondary-bg-color);
        border: none !important;
        padding: spacer(3) !important;
        box-shadow: $box-shadow;
        border-radius: 8px !important;

        @include screen-size(desktop) {
            max-width: 304px;
            margin-right: 16px;
        }
    }
}

.rc-tooltip-arrow {
    display: none !important;
}
