@import "../../../styles/tokens";
@import "../../../../node_modules/bulma/sass/utilities/mixins";

.after-call {
    background-color: $alter-dark;
    width: 100%;
    height: var(--window-height);
    color: $alter-white;

    &__wrapper {
        width: 100%;
        height: 100%;
        align-items: flex-start;
        padding: 32px 16px;
        @include tablet {
            align-items: center;
        }
    }

    &__card {
        background: rgba(255 255 255/ 6%);
        color: $alter-white;
        width: 100%;
        @include tablet {
            height: 552px;
            width: 421px;
        }
    }

    &__thick-text {
        @include tablet {
            @include headline3-bold;
        }
    }

    &__thin-text {
        color: $alter-white-60;

        @include tablet {
            @include headline3-medium;
        }
    }

    &__hr {
        background-color: $alter-gray-10;
        height: 1px;
        margin-top: 16px;

        @include tablet {
            margin-top: 32px;
        }
    }

    &__buttons {
        gap: 16px;
        margin-top: 32px;
    }

    &__primary-button {
        border: none;
        background-color: #26aa76;
    }

    &__transparent-button {
        background-color: transparent;
        color: $alter-white;
        border-color: $alter-white-30;

        &:hover {
            color: $alter-white;
            border-color: $alter-white;
        }
    }

    &__button-icon {
        vertical-align: text-bottom;
    }

    &__link {
        text-decoration: underline;
        cursor: pointer;
    }

    &__overlay {
        height: var(--window-height);
        position: absolute;
        z-index: 1;
    }

    &__typeform {
        height: var(--window-height);
        width: 410px;
    }

    .tf-v1-widget iframe {
        height: 99%;
    }
}
