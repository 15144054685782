@import "variables";
.b-button {
    font-size: map-get($font-sizes, 4);
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;
    display: inline-block;

    &:focus {
        :not(:focus-visible) {
            outline: none;
        }
    }

    &:hover {
        text-decoration: none;
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.button {
    &--accent {
        color: white;
        background-color: $alter-primary-lite;

        &:hover {
            color: white;
            background-color: color-get("green", 3);
        }

        &:disabled {
            background-color: color-get("gray", 0);
            color: color-get("gray", 1);
        }
    }

    &--regular {
        color: color-get("blue", 4);
        border: 1px solid color-get("blue", 2);
        background-color: white;

        &:hover {
            color: color-get("blue", 4);
            background-color: color-get("gray", 0);
            border: 1px solid color-get("blue", 4);
        }

        &:disabled {
            border-color: color-get("gray", 1);
            color: color-get("gray", 1);
            background-color: white;
        }
    }

    &--old {
        color: white;
        background-color: color-get("red", 1);

        &:hover {
            color: white;
            background-color: color-get("red", 2);
        }

        &:disabled {
            background-color: color-get("gray", 0);
            color: color-get("gray", 1);
        }
    }

    &--link {
        padding: 0;
        background: none;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        white-space: inherit;
        border: none;
        color: $alter-primary-lite;

        &:hover,
        &:active,
        &:focus {
            :not(:focus-visible) {
                outline: none;
            }

            color: color-get("green", 3);
        }
    }
}

.btn {
    &.btn-primary {
        font-weight: bold;

        &.btn-disabled {
            color: $disabled-color;
            background-color: $alter-lightgrey-background;
        }
    }
}
