@import "../../styles/tokens";

.phone-input {
    &__error-text {
        color: $alter-danger-lite;
        font-size: 18px;
        margin-top: 8px;
    }

    &__error-border {
        border-color: $alter-danger-lite !important;
    }
}
