$family-sans-serif: "FuturaPT", sans-serif;
$family-monospace: monospace;
$render-mode: optimizelegibility;
$size-1: 3.25rem;
$size-2: 1.875rem;
$size-3: 1.5rem;
$size-4: 1.25rem;
$size-5: 1.125rem;
$size-6: 1rem;
$size-7: 0.875rem;
$size-small: $size-6;
$size-normal: $size-5;
$size-medium: $size-4;
$size-large: $size-3;
$weight-light: 300;
$weight-normal: 400;
$weight-medium: 450;
$weight-medium-bold: 500;
$weight-semibold: 600;
$weight-bold: 700;

@mixin default-text {
    font-size: $size-normal;
    line-height: 1.33;
    font-weight: $weight-normal;
}

@mixin default-text-accent {
    @include default-text;

    font-weight: $weight-medium;
}
