@import "../tokens";
@import "../common";

@mixin button-primary {
    color: $alter-white;
    background-color: $alter-primary-lite;
    border: 3px solid transparent;

    &:hover {
        background-color: $alter-primary-dark;
        color: $alter-white;
        text-decoration: none;
    }

    &:focus {
        border-color: $alter-primary-10;
    }

    &:active {
        border-color: $alter-dark-60;
    }

    &:disabled {
        color: $alter-dark-60;
        background-color: $alter-gray;
    }
}
