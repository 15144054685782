@import "../../../../../node_modules/bulma/sass/utilities/mixins";
@import "../../../../styles/tokens";

.currency-selector {
    &__title {
        width: 100%;
        text-align: center;
    }

    &__img {
        height: 1em;
        vertical-align: -2px;
        display: none;

        @include tablet {
            display: inline;
        }
    }

    &__input {
        visibility: hidden;
        width: 0;
        height: 0;
    }

    &__buttons-group {
        background-color: $alter-gray-80;
        border-radius: 8px;
    }

    &__button.button.blm-button.is-borderless {
        margin-bottom: 0;
        background-color: $alter-gray-80;
        border-color: $alter-gray-80;
        transition:
            background-color 0.3s ease,
            color 0.3s ease,
            border-color 0.3s ease;
        color: $alter-dark-60;

        &:hover {
            background-color: $alter-gray;
            border-color: $alter-gray;
        }

        &.is-active {
            pointer-events: none;
            color: $alter-primary-lite;
            background-color: rgb(212 241 230 / 100%);
            border-color: rgb(212 241 230 / 100%);
        }
    }

    &__label {
        line-height: 16px;

        @include tablet {
            line-height: 18px;
        }
    }

    &__label-small {
        font-size: 16px;
        display: block;
        font-weight: 400;

        @include tablet {
            display: inline;
            font-size: inherit;
            font-weight: inherit;

            &::before {
                content: "(";
            }

            &::after {
                content: ")";
            }
        }
    }
}

.tooltip-display-none {
    display: none !important;
}
