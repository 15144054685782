@import "../../styles-branded/colors";
@import "../../styles-branded/typography";
@import "../../styles-branded/misc";
@import "../../../../node_modules/bulma/sass/form/shared";

.blm-radio.b-radio.radio {
    font-size: $size-5;
    padding: 12px 16px;
    margin-bottom: unset;

    .control-label {
        padding-left: 13px;
    }

    &.is-disabled {
        cursor: default;
        color: $alter-dark-20;

        .check {
            cursor: default;
            border-color: $alter-dark-20;
        }

        input[type="radio"] {
            cursor: default;
        }
    }

    &:hover,
    &:active,
    &:focus {
        .control-label {
            border-color: $alter-dark-60;
        }

        input[type="radio"] {
            & + .check {
                border-color: $alter-dark-20;
            }

            &:not(:disabled) {
                & + .check {
                    border-color: $alter-dark-60;
                }

                &:checked + .check {
                    border-color: $alter-primary-lite;
                }
            }
        }
    }

    &.box {
        transition: $alter-control-transition;
        box-shadow: none;
        background-color: $alter-gray-80;
        &.is-fullwidth {
            width: 100%;
        }

        &:focus {
            background-color: $alter-gray-80;
            outline: 3px solid $alter-primary-20;

            input[type="radio"]:not(:disabled) {
                & + .check {
                    border-color: $alter-primary-lite;
                }
            }
        }

        &:hover,
        &:active,
        &:focus {
            input[type="radio"]:not(:disabled) {
                & + .check {
                    transition: $alter-control-transition;
                }

                &:focus {
                    background-color: $alter-gray-80;
                    outline: 3px solid $alter-primary-20;

                    & + .check {
                        border-color: $alter-primary-lite;
                    }
                }

                &:hover,
                &:active {
                    background-color: $alter-gray;
                }
            }
        }

        &:hover,
        &:active {
            background-color: $alter-gray;
        }

        &:focus-within {
            outline: 3px solid $alter-primary-20;
        }

        &.is-active {
            background-color: $alter-primary-10;
        }

        &.is-disabled {
            background-color: transparent;
        }
    }
}
