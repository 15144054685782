@import "../../styles/tokens";
@import "../../ui-kit/styles-branded/misc";
@import "../../../node_modules/bulma/sass/utilities/mixins";

:root {
    --alter-footer-placeholder-height: 0;
}

.alter-footer {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--alter-secondary-text-color);
    width: 100%;
    height: 64px;
    padding: 20px 16px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: solid 1px var(--alter-separator-color);

    @include mobile {
        height: auto;
        padding: 24px 16px;
        border-top: none;
        margin-top: 0;
    }
}

.footer {
    &__default {
        @include tablet {
            background-color: var(--alter-primary-bg-color);
            padding-left: 32px;
            padding-right: 32px;
            border-top: 1px solid var(--alter-separator-color);
        }
    }

    &__in-booking {
        @include desktop {
            background-color: var(--alter-primary-bg-color);
            padding-left: 32px;
            padding-right: 32px;
            border-top: 1px solid var(--alter-separator-color);
            margin-top: 24px;
        }
    }

    &__fixed-button-profile {
        margin-bottom: 88px;
        @include tablet {
            margin-bottom: 0;
        }
    }

    &__fixed-button-booking {
        padding: 8px 16px;
        margin-bottom: var(--alter-footer-placeholder-height);
        @include desktop {
            margin-bottom: 0;
        }
    }

    &__left {
        display: none;
        @include tablet {
            display: flex;
            align-items: center;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        @include tablet {
            flex-direction: row;
        }
    }

    &__social-networks {
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;

        &--hide {
            order: 3;
            @include tablet {
                display: none;
            }
        }

        @include tablet {
            margin-left: 24px;
        }
    }

    &__social-network-wrapper {
        color: inherit;

        &:hover {
            color: var(--alter-secondary-hover-color);
            text-decoration: none;
        }
    }

    &__social-network-indent {
        margin-right: 8px;
        @include tablet {
            margin-right: 12px;
        }
    }

    &__meta-indent {
        margin-right: 4px;
        @include tablet {
            margin-right: 8px;
        }
    }

    &__social-network {
        text-decoration: underline;
    }

    &__emoji {
        height: 16px;
        width: 16px;
        margin-right: 4px;
    }

    &__exist-since {
        display: none;
        @include tablet {
            display: flex;
            align-items: center;
        }
    }
}

.footer-recommend-tech {
    order: 1;
    margin-bottom: 8px;

    @include tablet {
        order: 0;
        margin-right: 24px;
        margin-bottom: 0;
    }

    &__text {
        margin-right: 24px;
    }
    a {
        color: $alter-dark-60;
        text-decoration: none;
        position: relative;
        display: inline-block;
        white-space: nowrap;

        &::before {
            content: "";
            position: absolute;
            bottom: 3px;
            left: 0;
            width: 100%;
            height: 1px;
            background: $alter-dark-60;
        }

        &:hover {
            color: $alter-dark;
            &::before {
                background: $alter-dark;
            }
        }
    }
}

.footer-support {
    display: flex;
    white-space: nowrap;
    order: 0;
    margin-bottom: 8px;

    @include tablet {
        order: 1;
        margin-bottom: 0;
    }

    &__text {
        margin-right: 5px;
        display: none;
        @media (width >= 372px) {
            display: block;
        }
        @media (width >= 768px) {
            display: block;
            margin-bottom: 0;
        }
    }

    &__text-shorter {
        margin-right: 5px;
        @media (width >= 372px) {
            display: none;
        }
    }

    svg path {
        fill: var(--alter-primary-icon-color);
    }

    &__text,
    &__text-shorter {
        position: relative;
        cursor: pointer;
        transition: $alter-control-transition;

        &::before {
            content: "";
            position: absolute;
            bottom: 3px;
            left: 0;
            width: 100%;
            height: 1px;
            background: $alter-dark-60;
        }

        &:hover {
            color: $alter-dark;
        }
    }
}

.bottom-fixed {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: $alter-white;
    color: $alter-dark;

    @include tablet {
        display: none;
    }
}
