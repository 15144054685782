@import "../../styles/tokens";

.download-links {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    width: 170px;

    @include screen-size(desktop) {
        width: auto;
    }

    &__link-with-margin {
        margin-bottom: 16px;
        @include screen-size(desktop) {
            margin: 0;
        }
    }

    a {
        color: $alter-dark;
        margin-right: 24px;
        display: flex;
        align-items: center;

        &:hover {
            text-decoration: none;
            color: $alter-primary-dark;
        }

        span {
            margin-left: 8px;
            text-decoration: underline;
        }
    }
}
