@import "../../../node_modules/bulma/sass/utilities/mixins";
@import "styles/tokens";
@import "ui-kit/components-branded/Headline/styles";

.advice-block {
    position: relative;

    &__emoji {
        position: absolute;
        left: -177px;
        top: -30px;
        width: 176px;
    }

    &__title {
        @include headline3-bold;
    }

    &__emoji-mobile {
        display: none;
        position: relative;
        img {
            position: absolute;
            left: 0;
        }
    }

    &__button {
        cursor: pointer;
        display: inline-flex;
    }

    &.is-client {
        margin-bottom: 1.5rem;
        @include tablet {
            margin-bottom: 2rem;
        }

        .advice-block {
            &__emoji {
                @media (width <= 1240px) {
                    display: none;
                }
            }

            &__title {
                @include mobile {
                    @include alter-universal-body-text;

                    font-weight: 500;
                }
            }

            &__emoji-mobile {
                @media (width <= 1240px) {
                    display: block;
                    width: 110px;
                    margin-left: 16px;
                }
                @include mobile {
                    margin-left: 16px;
                    width: 64px;
                }
            }

            &__button {
                @include mobile {
                    width: 100%;
                }
            }
        }
    }

    &.is-booking {
        .advice-block {
            &__content {
                width: 672px;
                @media (width <= 991px) {
                    width: 100%;
                }
            }

            &__emoji {
                @media (width <= 1310px) {
                    display: none;
                }
            }

            &__title {
                @media (width <= 991px) {
                    @include alter-universal-body-text;

                    font-weight: 500;
                }
            }

            &__emoji-mobile {
                @media (width <= 1310px) {
                    display: block;
                    width: 110px;
                    margin-left: 16px;
                }
                @media (width <= 991px) {
                    width: 64px;
                }
            }

            &__button {
                @media (width <= 991px) {
                    width: 100%;
                }
            }
        }
    }
}
