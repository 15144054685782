@import "../../../../../node_modules/bulma/sass/utilities/mixins";

.client-account-selections-content {
    &__button {
        margin-bottom: 56px;
        display: inline-flex;
        @include mobile {
            margin-bottom: 24px;
            width: 100%;
        }
    }
}
