@import "variables";
@import "fonts";
@import "layout";
@import "tokens";
@import "functions";
@import "../ui-kit/styles-branded/misc";

@mixin psyalter-button {
    &-container {
        position: relative;
        width: 100%;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 210px;
    min-height: 40px;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-decoration: none;
    border: 1px solid $alter-green-text;
    border-radius: 27px;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    margin: 0 auto 10px;
    transition:
        background-color 0.3s,
        color 0.3s;

    &:last-child {
        margin-bottom: 0;
    }

    color: $alter-green-text;

    &:hover {
        color: #fff;
        background-color: $alter-green-focus;
        text-decoration: none;
    }

    &:focus {
        :not(:focus-visible) {
            outline: none;
        }
    }

    &:active {
        opacity: 0.7;
    }

    &:disabled {
        background-color: $alter-lightgrey-background;
        border-color: $alter-lightgrey-background;
        color: $alter-dark;
    }

    &-highlighted {
        color: #fff;
        background-color: $alter-green-active;

        &:hover {
            :not(:disabled) {
                background-color: $alter-green-focus;
            }
        }
    }

    &__fetching-spinner {
        display: flex;
        z-index: 1000;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.size-button {
    max-width: 210px;
    width: 100%;
}

@mixin link-like {
    display: block;
    color: $alter-green-text;
    font-weight: bold;
    cursor: pointer;

    svg path {
        fill: $alter-green-text;
    }

    &:hover,
    &:focus {
        color: $alter-green-focus;
        text-decoration: underline;

        svg path {
            fill: $alter-green-focus;
        }
    }

    &:active {
        color: $alter-green-active;
        text-decoration: underline;

        svg path {
            fill: $alter-green-active;
        }
    }
}

.link-like-button {
    @include link-like;
}

.link-like-button-disabled {
    @include link-like;

    color: $disabled-background;
}

@mixin header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    background-color: $alter-lightgrey-background;
}

@mixin header {
    position: relative;
    width: 100%;
    max-width: 980px;
    background-color: $alter-lightgrey-background;
    padding: 15px 10px 20px;

    @include screen-size(desktop) {
        padding: 90px 0 35px;
    }

    &__logo {
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 1;
        text-align: center;
        margin-bottom: 15px;

        @include screen-size(desktop) {
            position: absolute;
            top: 40px;
            left: 0;
            width: 90px;
            height: 90px;

            img {
                width: 90px;
                height: 90px;
            }
        }

        @media (width >= 1200px) {
            left: -100px;
        }

        @media (width >= 1440px) {
            left: -200px;
        }
    }

    &__title {
        @extend %font-futura-500;

        font-size: 30px;
        text-align: center;
        margin: 5px 0;

        @include screen-size(desktop) {
            font-size: 52px;
            margin-bottom: 10px;
        }
    }

    &__subtitle {
        @extend %font-futura-400;

        font-size: 16px;
        font-style: italic;
        text-align: center;
        opacity: 0.8;
        padding-left: 20px;
        padding-right: 20px;
        margin: 0 0 10px;

        @include screen-size(desktop) {
            font-size: 28px;
            margin-bottom: 20px;
        }
    }
}

@mixin questions {
    .questions {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition:
            max-height 0.2s,
            opacity 0.3s,
            margin-top 0.3s;
        background-color: #fff;
        box-shadow: 0 10px 40px 0 rgba(191 191 185 / 10%);

        &--show {
            max-height: 2000px;
            overflow: visible;
            opacity: 1;
            transition:
                max-height 0.3s,
                opacity 0.3s;
            margin-top: 15px;
        }

        &__list {
            list-style: none;
            padding: 0;
            margin: 0;

            &-item {
                @extend %font-futura-400;

                font-size: 10px;
                line-height: 0.8;
                border-bottom: 1px solid #ddddda;

                &:last-child {
                    border-bottom: none;
                }

                &-button {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    font-size: 16px;
                    text-align: left;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    outline: none;
                    opacity: 0.8;
                    padding: 20px 100px 15px 30px;

                    @include screen-size(desktop) {
                        font-size: 20px;
                        padding-left: 15px;
                    }

                    i {
                        position: absolute;
                        top: 50%;
                        right: 15px;
                        transform: translateY(-50%);
                        color: $alter-green-text;
                    }

                    .fa-plus,
                    .fa-times {
                        font-size: 18px;

                        @include screen-size(desktop) {
                            font-size: 21px;
                        }
                    }
                }

                &-text {
                    max-height: 3000px;
                    overflow: visible;
                    opacity: 1;
                    transition:
                        max-height 0.3s,
                        padding-bottom 0.4s,
                        opacity 0.3s;
                    padding-bottom: 20px;

                    @extend %font-futura-400;

                    font-size: 14px;
                    line-height: 1.2;
                    color: rgba(0 0 0 / 60%);
                    padding-left: 30px;
                    padding-right: 70px;
                    margin: 0;

                    @include screen-size(desktop) {
                        font-size: 18px;
                        padding-right: 100px;
                        padding-left: 15px;
                    }
                }
            }
        }
    }
}

@mixin alter-plus-gradient-background {
    background: #121fcf;
    background: linear-gradient(91.78deg, $alter-plus-1 0%, $alter-plus-2 100%);
}

@mixin alter-plus-shadow {
    box-shadow: 0 8px 56px -8px rgba(41 70 208/ 40%);
}

.circle {
    border-radius: 50%;
}

.width-max-content {
    width: max-content;
}

.ta-left {
    text-align: left !important;
}

@each $number, $r in $border-radiuses {
    .border-radius-#{$number} {
        border-radius: $r;
    }

    .border-radius-left-#{$number} {
        border-top-left-radius: $r;
        border-bottom-left-radius: $r;
    }

    .border-radius-top-#{$number} {
        border-top-left-radius: $r;
        border-top-right-radius: $r;
    }

    .border-radius-right-#{$number} {
        border-top-right-radius: $r;
        border-bottom-right-radius: $r;
    }

    .border-radius-bottom-#{$number} {
        border-bottom-right-radius: $r;
        border-bottom-left-radius: $r;
    }
}

.box-shadow-1 {
    box-shadow: 0 4px 6px rgba(37 37 3 /20%);
}

.box-shadow-2 {
    box-shadow: 0 0 4px rgba(50 54 72 /10%);
}

@each $basis, $value in $flex-basis {
    .flex-basis-#{$basis} {
        flex-basis: $value;
    }

    @each $breakpoint, $_ in $grid-breakpoints {
        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            .flex-basis-#{$breakpoint}-#{$basis} {
                flex-basis: $value;
            }
        }
    }
}

.list-disc {
    list-style-type: disc;
}

.overflow-hidden {
    overflow: hidden;
}

.no-decoration {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.icon-link {
    path {
        fill: $alter-dark-30;
        transition: $alter-control-transition;
    }

    &:hover {
        path {
            fill: $alter-primary-dark;
        }
    }

    &-alter-plus {
        &:hover {
            path {
                fill: $alter-advanced-indigo;
            }
        }
    }
}

.is-nowrap {
    white-space: nowrap;
}

.hover-alter-plus:hover {
    color: $alter-plus-2 !important;
}
