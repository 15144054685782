@import "../../../../styles/tokens";

.date-picker {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;
    user-select: none;

    &__week {
        display: flex;
        justify-content: space-around;

        &__short {
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            color: $alter-dark;
        }
    }

    &__day {
        display: flex;
        height: 40px;
        width: 40px;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 18px;
        margin: 0;
        @media (width >= 430px) {
            margin: 4px;
        }

        &-today {
            border-radius: 50%;
            border: 2px solid rgb(41 185 129 / 20%);
            border: 2px solid $alter-primary-lite;
        }

        &-has-available-hours {
            border-radius: 50%;
            cursor: pointer;
        }

        &-no-available-hours {
            border-radius: 50%;
            pointer-events: none;
            text-decoration-line: line-through;
            color: $alter-dark-20;
        }

        &-today-has-no-available-hours {
            border-radius: 50%;
            border: 2px solid rgb(41 185 129 / 20%);
            pointer-events: none;
            text-decoration-line: line-through;
            color: $alter-dark-20;
        }

        &-selected {
            background-color: $alter-primary-lite;
            color: white;
            border-radius: 50%;
        }
    }

    &__week-day-short {
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        color: rgb(50 54 72 / 60%);
        font-size: 18px;
        font-weight: 400;
        margin: 4px;

        &-container {
            display: flex;
            justify-content: space-around;
        }
    }

    &__change-week {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    &__slots {
        display: flex;
        flex-wrap: wrap;
        margin-top: 4px;
        margin-left: -16px;

        &__slot {
            cursor: pointer;
            background-color: $alter-gray-80;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 88px;
            height: 48px;
            font-weight: 500;
            font-size: 18px;
            margin-top: 16px;
            margin-left: 16px;

            &-selected {
                background: rgb(41 185 129 / 20%);
            }
        }
    }

    &__select-time {
        font-size: 18px;
        line-height: 24px;
        color: $alter-dark;
        font-weight: 500;
        margin-top: 30px;
        margin-bottom: 3px;
    }

    &__timezone {
        color: rgb(50 54 72 / 60%);
        font-size: 16px;
        margin-bottom: 4px;
        font-weight: 400;
    }

    &__error-message {
        color: $alter-danger-lite;
        margin-top: 30px;
    }

    &__spinner-container {
        min-height: 372px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
    }
}
