@import "../../ui-kit/styles-branded/colors";

.certificate-block {
    &__head {
        display: flex;
    }

    &__description {
        color: $alter-dark-60;
    }

    &__image {
        width: 24px;
        flex-shrink: 0;
    }
}
