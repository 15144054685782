@import "../../styles/tokens";
@import "../../ui-kit/components-branded/Headline/styles";

.hr-account {
    &__ballance-box {
        padding-left: 24px !important;
        padding-right: 24px !important;
        border: solid $alter-advanced-yellow 2px;
    }

    &__report-box {
        @include tablet {
            padding-top: 32px !important;
            padding-bottom: 32px !important;
        }
    }

    &__value {
        @include headline3-bold;
    }

    &__report-header {
        @include headline3-bold;
    }

    &__plate-wrapper {
        gap: 16px;
        margin-top: 24px;
        margin-bottom: 24px;
        @include tablet {
            gap: 24px;
            margin-top: 32px;
            margin-bottom: 32px;
        }
    }

    &__plate-item {
        &.box.blm-box {
            flex-basis: calc(50% - 8px);
            margin-bottom: 0;
            box-shadow: none;
            background-color: $alter-gray-80;
            padding: 16px;
            @include tablet {
                padding: 24px;
            }

            &:not(:last-child) {
                margin-bottom: 0;
            }
        }
    }

    &__download-button {
        width: 100%;
    }

    &__align-right {
        text-align: right !important;
    }

    &__data-row {
        &:not(:last-child) {
            padding-bottom: 8px;
        }
    }
}
