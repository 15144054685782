@import "../../../../node_modules/bulma/sass/utilities/mixins";

@import "../../../styles/tokens";

.in-call-banner {
    &__wrapper {
        width: 100%;
        min-height: 32px;
        border-radius: 4px;
        background: rgba(50 54 72 / 30%);
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;

        @supports (backdrop-filter: blur(40px)) {
            background: rgba(50 54 72 / 30%);
            backdrop-filter: blur(40px);
        }

        @include mobile {
            bottom: unset;
            top: 0;
        }
    }

    &__text {
        @include alter-universal-secondary;

        color: $alter-white;
        padding: 4px 0 4px 16px;
        @include tablet {
            text-align: center;
            display: flex;
            justify-content: center;
            gap: 4px;
        }
    }

    &__close-icon {
        width: 24px;
        height: 24px;
        min-width: 24px;
        margin: 4px;
        cursor: pointer;

        svg {
            color: $alter-white;
        }
    }
}
